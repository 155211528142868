import React, { memo } from 'react'

const TableInput = ({currentInputValue,handleInputChange, field}) => {
  
  return(
    <div className='table-cell-bottom'>
      <input onChange={(e) => handleInputChange(e, field)} type='text' value={currentInputValue} />
    </div>
  )
}

export default memo(TableInput)