import { configureStore, combineReducers } from "@reduxjs/toolkit";
import appReducer from "stateMgmt/appSlice";
import reportReducer from "stateMgmt/reportSlice";
import sessionReducer from "stateMgmt/sessionSlice";
import complianceReducer from "stateMgmt/complianceSlice";
import sessionMiddleware from "middlewares/sessionMiddleware";
import {
  // asyncFunctionMiddleware,
  reduxLogger,
  crashReporter,
} from "./middleware/AppMiddleware";

const rootReducer = combineReducers({
  appState: appReducer,
  reportState: reportReducer,
  sessionState: sessionReducer,
  complianceState: complianceReducer,
});

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      // .concat(asyncFunctionMiddleware)
      .concat(sessionMiddleware)
      .concat(reduxLogger)
      .concat(crashReporter),
});

export default store;
