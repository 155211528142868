import React, { useEffect } from "react";
import { Grid, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCurrentState } from "stateMgmt/appSlice";
import logger from "services/LoggingService";

import "../App.scss";

function Header() {
  const currentState = useSelector(selectCurrentState);
  useEffect(() => {
    logger.debug("Header UseEffect. currentstate: ", currentState);
  });

  return (
    <>
      <div className="App-header">
        <Grid container display="flex">
          <Grid item xs={9}>
            PEPFAR Data Genie (Version: x.x.x)
          </Grid>
          <Grid item xs={2}>
            Users Name Here (username)
          </Grid>
          <Grid item xs={1}>
            <Button
              variant="contained"
              sx={{
                height: "3vh",
                width: "8vw",
                margin: "auto",
                fontSize: "0.5rem",
                color: "#000000",
                backgroundColor: "#ffffff",
              }}
              //   onClick={() => {
              //     runReport();
              //   }}
            >
              Return to DATIM
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Header;
