/* eslint-disable no-unused-vars */
import React, { createRef, memo, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { animated, useSpring } from "react-spring";
import { useSelector, useDispatch } from "react-redux";
import { selectReportState } from "stateMgmt/reportSlice";
import { selectSessionState } from "stateMgmt/sessionSlice";

import {
  CEE_SCORE,
  CEE_SCORE_DETAIL,
  DATA_REVIEW_TOOL_EXTRACT,
  OU_BY_IM,
  PSNU_BY_IM,
  SITE_BY_IM_EXTRACT,
} from "constants/ReportTypes";
import { PARAMETER_FILTER_HEIGHT } from "constants/Session";
import { selectCurrentState, setRetportState } from "stateMgmt/appSlice";
import Button from "components/Button";
import logger from "services/LoggingService";

import "./parameterBox.scss";
import "components/Button/button.scss";
// import {
//   clearEverything,
//   initiateSIMSSevenZipExport,
//   initiateSIMSTextExport,
//   initiateSIMSZipExport
// } from 'actions/SiteByIMExtractActions'
// import {
//   clearEverythingPSNU,
//   initiatePSNUSevenZipExport,
//   initiatePSNUTextExport,
//   initiatePSNUZipExport
// } from 'actions/PSNUByIMExtractActions'
// import {
//   clearEverythingOU,
//   initiateOUSevenZipExport,
//   initiateOUTextExport,
//   initiateOUZipExport
// } from 'actions/OUByIMExtractActions'
// import SIMSParameters from './Filters/SIMSParameters'
// import PSNUParameters from './Filters/PSNUParameters'
// import OUParameters from './Filters/OUParameters'
// import CEEScoreParameters from './Filters/CEEScoreParameters'
// import CEEScoreDetailParameters from './Filters/CEEScoreDetailParameters'
// import {
//   clearEverythingCEEScore,
//   initiateCEEScoreXLSXExport
// } from 'actions/CEEScoreActions'
// import {
//   clearEverythingCEEScoreDetail,
//   initiateCEEScoreDetailXLSXExport
// } from 'actions/CEEScoreDetailActions'
// import {
//   clearEverythingDRT,
//   initiateDRTTextExport
// } from 'actions/DRTExtractActions'
// import DRTParameters from './Filters/DRTParameters'

const ParameterBox = ({ isParameterFilterBoxOpen, setParameterFilterBox }) => {
  // FIXME: Use Report Slice
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentState = useSelector(selectCurrentState);
  const currentReportState = useSelector(selectReportState);
  const currentSessionState = useSelector(selectSessionState);

  const currentReportName = currentReportState.currentReportName;
  // const selectedCountryNames = currentSessionState.selectedCountryNames;
  const top3Levels = currentState.top3Levels;

  const currentReportCount = currentReportState.currentReportCount;
  const currentParameterCount = currentReportState.currentParameterCount;
  const isTextExportLoading = currentReportState.isTextExportLoading;
  const isZipExportLoading = currentReportState.isZipExportLoading;
  const isSevenZipExportLoading = currentReportState.isSevenZipExportLoading;

  let handleClearFilters;
  let handle7zip;
  let handleZip;
  let handleText;

  const endReport = (maintain) => {
    logger.debug(`End Report. Maintain state: ${maintain}`);
    dispatch(
      setRetportState({ displayReport: false, maintainReportState: maintain })
    );
    navigate("/");
  };

  switch (currentReportName) {
    case SITE_BY_IM_EXTRACT:
      handle7zip = () => {
        // dispatch(initiateSIMSSevenZipExport());
      };
      handleZip = () => {
        // dispatch(initiateSIMSZipExport());
      };
      handleText = () => {
        // dispatch(initiateSIMSTextExport());
      };
      handleClearFilters = () => {
        // dispatch(clearEverything());
      };
      break;
    case PSNU_BY_IM:
      handle7zip = () => {
        // dispatch(initiatePSNUSevenZipExport());
      };
      handleZip = () => {
        // dispatch(initiatePSNUZipExport());
      };
      handleText = () => {
        // dispatch(initiatePSNUTextExport());
      };
      handleClearFilters = () => {
        // dispatch(clearEverythingPSNU());
      };
      break;
    case OU_BY_IM:
      handle7zip = () => {
        // dispatch(initiateOUSevenZipExport());
      };
      handleZip = () => {
        // dispatch(initiateOUZipExport());
      };
      handleText = () => {
        // dispatch(initiateOUTextExport());
      };
      handleClearFilters = () => {
        // dispatch(clearEverythingOU());
      };
      break;
    case CEE_SCORE:
      handleText = () => {
        // dispatch(initiateCEEScoreXLSXExport());
      };
      handleClearFilters = () => {
        // dispatch(clearEverythingCEEScore());
      };
      break;
    case CEE_SCORE_DETAIL:
      handleText = () => {
        // dispatch(initiateCEEScoreDetailXLSXExport());
      };
      handleClearFilters = () => {
        // dispatch(clearEverythingCEEScoreDetail());
      };
      break;
    case DATA_REVIEW_TOOL_EXTRACT:
      handleText = () => {
        // dispatch(initiateDRTTextExport());
      };
      handleClearFilters = () => {
        // dispatch(clearEverythingDRT());
      };
      break;
  }

  const ParameterList = () => {
    // const allCountryNames = "";
    const countryNameKey = {};
    const addRegionToList = (regionalIndexNum) => {
      // top3Levels.level2[regionalIndexNum].children.forEach(
      //   (country, indexNum) => {
      //     if (!countryNameKey[country.label]) {
      //       countryNameKey[country.label] = true;
      //     }
      //   }
      // );
    };

    // selectedCountryNames.forEach((countryName, index) => {
    //   // Turn global or regional into list of countries, avoiding duplicates
    //   if (countryNameKey[countryName]) {
    //     // Already exists; do nothing
    //   } else if (countryName === "Global") {
    //     // Iterate through all regions
    //     for (let x = 0; x < 4; x++) {
    //       addRegionToList(x);
    //     }
    //   } else if (countryName === "Africa") {
    //     addRegionToList(0);
    //   } else if (countryName === "Americas") {
    //     addRegionToList(1);
    //   } else if (countryName === "Asia") {
    //     addRegionToList(2);
    //   } else if (countryName === "Europe") {
    //     addRegionToList(3);
    //   } else {
    //     // Not global or region
    //     countryNameKey[countryName] = true;
    //   }
    // });

    // Turn into string
    // allCountryNames = Object.keys(countryNameKey).sort((a, b) => a.localeCompare(b)).join(', ')
    return (
      <div className="report-parameters-content">
        {/* <ul id="report-parameters-content-inner-wrap">
                    <SIMSParameters
                        isShowing={currentReportName === SITE_BY_IM_EXTRACT}
                    />
                    <PSNUParameters
                        isShowing={currentReportName === PSNU_BY_IM}
                    />
                    <OUParameters isShowing={currentReportName === OU_BY_IM} />
                    <CEEScoreParameters
                        isShowing={currentReportName === CEE_SCORE}
                    />
                    <CEEScoreDetailParameters
                        isShowing={currentReportName === CEE_SCORE_DETAIL}
                    />
                    <DRTParameters
                        isShowing={
                            currentReportName === DATA_REVIEW_TOOL_EXTRACT
                        }
                    />
                </ul> */}
      </div>
    );
  };

  const topHalf = 66;
  const bottomHalfPadding = 20; // 10px top and bottom
  const bottomHalf =
    bottomHalfPadding + PARAMETER_FILTER_HEIGHT * currentParameterCount;
  const scaleTo = 1 - bottomHalf / (topHalf + bottomHalf);
  const checkCount = createRef();

  const [animateBox, setBoxAnimation] = useSpring(() => ({
    scaleY: 1,
    transformOrigin: "top",
  }));
  const [rotateArrowAnimation, setRotateArrowAnimation] = useSpring(() => ({
    rotate: "180deg",
  }));
  const [animateParameterOverlay, setParameterOverlay] = useSpring(() => ({
    translateY: "0px",
  }));

  useEffect(() => {
    setBoxAnimation.start(() => ({
      to: {
        scaleY: isParameterFilterBoxOpen ? 1 : scaleTo,
        transformOrigin: "top",
      },
      config: {
        duration: checkCount.current ? 0 : undefined,
      },
    }));
    setRotateArrowAnimation.start(() => ({
      to: {
        rotate: isParameterFilterBoxOpen ? "180deg" : "0deg",
      },
    }));

    setParameterOverlay.start(() => ({
      to: {
        translateY: isParameterFilterBoxOpen ? "0px" : `-${bottomHalf}px`,
      },
      config: {
        duration: checkCount.current ? 0 : undefined,
      },
    }));
  }, [isParameterFilterBoxOpen, currentParameterCount]);

  useLayoutEffect(() => {
    // This determines if the animation duration needs to be immediate or not
    checkCount.current = currentParameterCount;
  }, [currentParameterCount]);

  return (
    <div
      className="parameters"
      style={{
        height: `${topHalf + bottomHalf}px`,
      }}
    >
      <animated.div
        className="clickable-box"
        onClick={() => setParameterFilterBox(!isParameterFilterBoxOpen)}
        style={animateBox}
      ></animated.div>
      <div
        className="top"
        style={{
          height: `${topHalf}px`,
        }}
      >
        <div className="left">
          <animated.span className="arrow-wrap" style={rotateArrowAnimation}>
            <ExpandMoreIcon
              sx={{
                height: "40px",
                width: "40px",
              }}
            />
          </animated.span>
          Report Parameters
        </div>

        {currentReportName !== CEE_SCORE &&
        currentReportName !== CEE_SCORE_DETAIL &&
        currentReportName !== DATA_REVIEW_TOOL_EXTRACT ? (
          <div className="parameter-button-wrap">
            <div className="first-two-buttons">
              <Button
                color={"rgb(103, 103, 103)"}
                click={endReport}
                margin="0 10px 0 0"
              >
                Clear Filters
              </Button>
              <Button
                color={"#337ab7"}
                click={handle7zip}
                margin="0 10px 0 0"
                isDisabled={isTextExportLoading}
                isLoading={isSevenZipExportLoading}
              >
                {isSevenZipExportLoading ? null : (
                  <FileDownloadIcon
                    sx={{
                      height: "18px",
                      width: "18px",
                      marginRight: "6px",
                    }}
                  />
                )}
                Schedule Export using 7-Zip
              </Button>
            </div>
            <div className="last-two-buttons">
              <Button
                color={"#f0ad4e"}
                click={handleZip}
                margin="0 10px 0 0"
                isDisabled={isTextExportLoading}
                isLoading={isZipExportLoading}
              >
                {isZipExportLoading ? null : (
                  <FileDownloadIcon
                    sx={{
                      height: "18px",
                      width: "18px",
                      marginRight: "6px",
                    }}
                  />
                )}
                Schedule Export using Zip
              </Button>
              <Button
                color={"#5cb85c"}
                click={handleText}
                margin="0 10px 0 0"
                isDisabled={isTextExportLoading || currentReportCount > 9500000}
                isLoading={isTextExportLoading}
              >
                {isTextExportLoading ? null : (
                  <FileDownloadIcon
                    sx={{
                      height: "18px",
                      width: "18px",
                      marginRight: "6px",
                    }}
                  />
                )}
                Export Now as TXT
              </Button>
            </div>
          </div>
        ) : (
          <div className="parameter-button-wrap-alternate">
            <div className="first-two-buttons">
              <Button
                color={"rgb(103, 103, 103)"}
                click={handleClearFilters}
                margin="0 10px 0 0"
              >
                Clear Filters
              </Button>
              <Button
                color={"#5cb85c"}
                click={handleText}
                margin="0 10px 0 0"
                isDisabled={isTextExportLoading || currentReportCount > 9500000}
                isLoading={isTextExportLoading}
              >
                {isTextExportLoading ? null : (
                  <FileDownloadIcon
                    sx={{
                      height: "18px",
                      width: "18px",
                      marginRight: "6px",
                    }}
                  />
                )}
                Export Now as XLSX
              </Button>
            </div>
          </div>
        )}
      </div>

      <div
        className="bottom"
        style={{
          height: `${bottomHalf}px`,
        }}
      >
        <ParameterList />
      </div>

      <animated.div
        className="parameter-cover"
        style={{
          height: `${PARAMETER_FILTER_HEIGHT * currentParameterCount + 500}px`,
          top: `${topHalf + bottomHalf}px`,
          left: "-5px",
          ...animateParameterOverlay,
        }}
      ></animated.div>
    </div>
  );
};
export default memo(ParameterBox);
/* eslint-enable */
