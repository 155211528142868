import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { reportList } from "assets/data/reportConstants";
import { axiosFilterSession } from "services/AxiosInstance";

import logger from "services/LoggingService";

const initialState = {
  isFrozen: false,
  currentReportName: reportList.reports[0].name,
  reports: [...reportList.reports],
  currentTableData: null,
  isTablePageLoading: false,
  isTextExportLoading: false,
  isZipExportLoading: false,
  isSevenZipExportLoading: false,
  isMyDownloadsLoading: false,
  hasTableLoadingFailed: false,
  currentTableIncrement: 0,
  currentTablePage: 0,
  currentReportCount: 0,
  currentParameterCount: 0,
  currentTableWidth: 0,
  currentSearchList: [],
  currentSortedColumn: 0,
  currentSortDirection: "",
  DRTExtract: {
    drtPeriods: null,
    activeSNU1: null,
    activePSNU: null,
    activeCheckLevel: null,
    activeFundingAgency: null,
    activeMechanisms: null,
    activeProgramArea: null,
    activeFlag: null,
    activeSNU1Saved: null,
    activePSNUSaved: null,
    activeCheckLevelSaved: null,
    activeFundingAgencySaved: null,
    activeMechanismsSaved: null,
    activeProgramAreaSaved: null,
    activeFlagSaved: null,
    snu1: null,
    psnu: null,
    checkLevel: null,
    fundingAgency: null,
    mechanisms: null,
    programArea: null,
    flag: null,
  },
  CEEScoreDetail: {
    activeFundingAgency: null,
    activeCountryName: null,
    activePeriod: null,
    activeAssessmentType: null,
    activeCoreEssentialElements: null,
    activeCountryNameSaved: null,
    activeFundingAgencySaved: null,
    activePeriodSaved: null,
    activeAssessmentTypeSaved: null,
    activeCoreEssentialElementsSaved: null,
    fundingAgency: null,
    countryName: null,
    period: null,
    assessmentType: null,
    coreEssentialElements: null,
  },
  CEEScore: {
    isFrozen: false,
    activeFundingAgency: null,
    activeCountryName: null,
    activePeriod: null,
    activeAssessmentType: null,
    activeCoreEssentialElements: null,
    activeCountryNameSaved: null,
    activeFundingAgencySaved: null,
    activePeriodSaved: null,
    activeAssessmentTypeSaved: null,
    activeCoreEssentialElementsSaved: null,
    fundingAgency: null,
    countryName: null,
    period: null,
    assessmentType: null,
    coreEssentialElements: null,
    isCEEScoreXLSXExportLoading: null,
  },
  OUByIMExtract: {
    isFrozen: false,
    isFrozenSaved: null,
    activeFundingAgency: null,
    activeMechanisms: null,
    activeIndicator: null,
    activeStandardizedDisaggregate: null,
    activeModality: null,
    activeFiscalYear: null,
    savedRadio: null,

    activeFundingAgencySaved: null,
    activeMechanismsSaved: null,
    activeIndicatorSaved: null,
    activeStandardizedDisaggregateSaved: null,
    activeModalitySaved: null,
    activeFiscalYearSaved: null,

    savedCurrentCountryUIDString: null,
    savedSelectedCountryNames: null,
    fiscalYear: null,
    indicator: null,
    fundingAgency: null,
    mechanisms: null,
    standardizedDisaggregate: null,
    modality: null,
    isOUTextExportLoading: null,
    isOUZipExportLoading: null,
    isOUSevenZipExportLoading: null,
  },
  PSNUByIMExtract: {
    isFrozen: false,
    currentCountryUIDString: null,
    currentParameterCount: null,
    selectedCountryNames: null,
    isCurrentTableLoading: null,
    initiatePSNUTableLoad: null,
    initiatePSNUPageLoad: null,
    activeSNU1: null,
    activePSNU: null,
    activeFundingAgency: null,
    activeMechanisms: null,
    activeIndicator: null,
    activeStandardizedDisaggregate: null,
    activeModality: null,
    activeFiscalYear: null,
    savedRadio: null,
    activeSNU1Saved: null,
    activePSNUSaved: null,
    activeFundingAgencySaved: null,
    activeMechanismsSaved: null,
    activeIndicatorSaved: null,
    activeStandardizedDisaggregateSaved: null,
    activeModalitySaved: null,
    activeFiscalYearSaved: null,
    fiscalYear: null,
    indicator: null,
    snu1: null,
    psnu: null,
    fundingAgency: null,
    mechanisms: null,
    standardizedDisaggregate: null,
    isFrozenSaved: null,
    isDreamsSaved: null,
    isPSNUTextExportLoading: null,
    isPSNUZipExportLoading: null,
    isPSNUSevenZipExportLoading: null,
  },
  SiteByIMExtract: {
    isFrozen: false,
    filters: [],
    activeSNU1: null,
    activePSNU: null,
    activeFundingAgency: null,
    activeMechanisms: null,
    activeIndicator: null,
    activeStandardizedDisaggregate: null,
    activeModality: null,
    activeFacility: null,
    activeFiscalYear: null,
    savedSNU1: null,
    savedPSNU: null,
    savedFundingAgency: null,
    savedMechanisms: null,
    savedIndicator: null,
    savedRadio: null,
    savedStandardizedDisaggregate: null,
    savedModality: null,
    savedFacility: null,
    savedFiscalYear: null,
    savedIsFrozen: null,
    savedIsDreams: null,
    fiscalYear: null,
    indicator: null,
    snu1: null,
    psnu: null,
    fundingAgency: null,
    mechanisms: null,
    standardizedDisaggregate: null,
    modality: null,
    facility: null,
    isSIMSTextExportLoading: null,
    isSIMSZipExportLoading: null,
    isSIMSSevenZipExportLoading: null,
  },
};

export const fetchFilters = createAsyncThunk(
  "reportState/getFilters",
  async (options) => {
    const axios = axiosFilterSession(options.url);
    const response = await axios.get(options.url);
    return response;
  }
);

export const reportSlice = createSlice({
  name: "reportState",
  initialState,
  reducers: {
    getFilters: (state, action) => {
      logger.debug("getFilters called");
      const newState = {
        ...state,
        ...action.payload,
      };
      return newState;
    },
    updateReportState: (state, action) => {
      logger.debug("Running updateReportState:", action.payload);
      const newState = {
        ...state,
        ...action.payload,
      };
      return newState;
    },
  },
});

export const selectReportState = (state) => state.reportState;
export const selectReportName = (state) => state.reportState.currentReportName;
export const selectIsFrozen = (state) => state.reportState.isFrozen;
export const { getFilters, updateReportState } = reportSlice.actions;

export default reportSlice.reducer;
