import React, { memo, createRef, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { VariableSizeGrid } from "react-window";

const MyDownloadsTable = ({
  isAuthenticated,
  reportMapping,
  Cell,
  HeaderCell,
  filteredAndSortedTableData,
}) => {
  const isMyDownloadsLoading = useSelector(
    (state) => state.sessionState.isMyDownloadsLoading
  );
  const headerRow = createRef();
  const [scrollbarOffset, setScrollbarOffset] = useState(0);
  const [currentTableWidth, setCurrentTableWidth] = useState(0);

  // Set the table width and scrollbar offset
  const determineTableWidth = () => {
    const bottomTable = document.getElementsByClassName("downloads-table-grid");
    const headerRowScrollbarOffset =
      bottomTable &&
      bottomTable[0] &&
      bottomTable[0].offsetWidth - bottomTable[0].clientWidth;
    setScrollbarOffset(
      headerRowScrollbarOffset > 0 ? headerRowScrollbarOffset : 0
    );

    const navbarElement = document.getElementById("navbar").clientWidth;
    const tableWidth = navbarElement - 293 - 30;
    if (
      currentTableWidth &&
      bottomTable &&
      bottomTable[0] &&
      tableWidth > bottomTable[0].scrollWidth
    )
      setCurrentTableWidth(bottomTable[0].scrollWidth);
    else setCurrentTableWidth(navbarElement - 293 - 30);
  };
  useLayoutEffect(() => {
    determineTableWidth();
  });

  const handleScrolling = () => {
    const bottomTable = document.getElementsByClassName("downloads-table-grid");
    if (headerRow.current.state.scrollLeft !== bottomTable[0].scrollLeft)
      headerRow.current.scrollTo({ scrollLeft: bottomTable[0].scrollLeft });
  };

  if (isMyDownloadsLoading && !filteredAndSortedTableData.length)
    return (
      <div className="my-downloads-loading-text">
        <span className="my-downloads-loading-icon"></span>Loading your
        downloads... please wait
      </div>
    );
  else
    return (
      <div
        className="table-wrap"
        style={{ zIndex: isAuthenticated ? "2" : "auto" }}
      >
        <div
          className="table-body"
          style={{ width: `${currentTableWidth}px`, height: "500px" }}
        >
          <VariableSizeGrid
            columnCount={reportMapping.length}
            columnWidth={(index) =>
              index + 1 === reportMapping.length
                ? reportMapping[index].width + scrollbarOffset
                : reportMapping[index].width
            }
            height={60}
            ref={headerRow}
            rowCount={1}
            rowHeight={() => 60}
            style={{
              overflowX: "hidden",
              overflowY: "hidden",
            }}
            width={currentTableWidth}
          >
            {HeaderCell}
          </VariableSizeGrid>
          <VariableSizeGrid
            columnCount={reportMapping.length}
            className="downloads-table-grid"
            id="table-grid"
            rowCount={
              filteredAndSortedTableData.length
                ? filteredAndSortedTableData.length
                : 0
            }
            columnWidth={(index) => reportMapping[index].width}
            rowHeight={() => 30}
            height={300}
            overscanRowCount={5}
            width={currentTableWidth}
            onScroll={handleScrolling}
          >
            {Cell}
          </VariableSizeGrid>
        </div>
      </div>
    );
};

export default memo(MyDownloadsTable);
