/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { selectReportName } from "stateMgmt/reportSlice";
import ParameterBox from "./Reports/Reports/ParameterBox/ParameterBox";

import logger from "services/LoggingService";

import "App.scss";

const DisplayReport = (props) => {
  const currentReportName = useSelector(selectReportName);
  const [isParameterFilterBoxOpen, setParameterFilterBox] = useState(true);

  useEffect(() => {
    logger.debug("Pre-Processing DisplayReport", currentReportName);
  }, [currentReportName]);

  return (
    <>
      <div id="DISPLAY" className="staging-container">
        <div className="display-header">
          <h1>{currentReportName}</h1>
          <h2>DATIM Data as of:</h2>
          <h2>Genie report updated:</h2>
          <h3>Current period(s):</h3>
          <div className="link-div">
            <a
              href="https://datim.zendesk.com/hc/en-us/articles/360033053192"
              target="_blank"
              rel="noopener noreferrer"
            >
              Users Guide and Data Dictionary
            </a>
          </div>
        </div>
        <div>
          <ParameterBox
            isParameterFilterBoxOpen={isParameterFilterBoxOpen}
            setParameterFilterBox={setParameterFilterBox}
          />
        </div>
        <div className="report-container">Report Here</div>
      </div>
    </>
  );
};

export default React.memo(DisplayReport);
/* eslint-enable no-unused-vars */
