import clsx from 'clsx'
import React, { memo } from 'react'
import { CircularProgress } from '@mui/material'
import { useSpring, animated } from 'react-spring'

const Button = (props) => {
    const {
        isDisabled,
        isReportButton,
        click,
        color,
        margin,
        children,
        isLoading,
        isRefreshDownloadButton,
    } = props
    const [borderAnimation, setBorderAnimation] = useSpring(() => ({
        opacity: 0,
    }))
    const handleClick = () => {
        setBorderAnimation.start({
            from: { opacity: 0 },
            to: [{ opacity: 0.8 }, { opacity: 0 }],
            config: { duration: 50 },
        })
        click()
    }
    return (
        <div
            className={clsx('button', isReportButton && 'report-button')}
            onClick={isDisabled ? undefined : handleClick}
            style={{ backgroundColor: color, margin: margin || undefined }}
        >
            <div className="button-overlay"></div>
            <div className="button-content">
                {isLoading && (
                    <div className="button-loading">
                        <CircularProgress
                            size={14}
                            sx={{
                                color: isRefreshDownloadButton
                                    ? '#a6e7ff'
                                    : undefined,
                            }}
                        />
                    </div>
                )}
                {children}
            </div>
            <animated.div className="button-border" style={borderAnimation}>
                <div className="inner-button-border">
                    <div></div>
                </div>
            </animated.div>
            {isDisabled && <div className="button-disabled"></div>}
        </div>
    )
}

export default memo(Button)
