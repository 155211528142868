import React, { memo } from "react";
import { useSelector } from "react-redux";
import { selectReportState } from "stateMgmt/reportSlice";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// import {
//   initiateIncrementChange
// } from 'actions/sessionActions'

const RowsPerPage = () => {
  // const dispatch = useDispatch()
  const currentReportState = useSelector(selectReportState);
  const reportCount = currentReportState.currentReportCount;
  const tableIncrement = currentReportState.currentTableIncrement;
  const currentPageNum = currentReportState.currentTablePage;

  const MenuProps = {
    PaperProps: {
      style: {
        transform: "translateY(-9px)",
      },
    },
  };

  const handleChange = (e) => {
    // dispatch(initiateIncrementChange(e.target.value))
  };

  const formatNumber = new Intl.NumberFormat("en-US");
  const reportCountFormatted = formatNumber.format(reportCount);
  let rowsPerPageString = "Items ";

  if (currentPageNum === 1) {
    const secondNumber =
      tableIncrement > reportCount ? reportCount : tableIncrement;
    const secondNumberFormatted = formatNumber.format(secondNumber);
    rowsPerPageString += `1 - ${secondNumberFormatted} of ${reportCountFormatted}`;
  } else {
    const firstNumber = (currentPageNum - 1) * tableIncrement + 1;
    const secondNumber =
      firstNumber + tableIncrement - 1 > reportCount
        ? reportCount
        : firstNumber + tableIncrement - 1;
    const firstNumberFormatted = formatNumber.format(firstNumber);
    const secondNumberFormatted = formatNumber.format(secondNumber);
    rowsPerPageString += `${firstNumberFormatted} - ${secondNumberFormatted} of ${reportCountFormatted}`;
  }

  return (
    <div className="rows-per-page-wrapper">
      {rowsPerPageString}
      <FormControl>
        <InputLabel
          disableAnimation={true}
          id="rows-per-page"
          shrink={false}
        ></InputLabel>
        <Select
          labelId="rows-per-page"
          IconComponent={ExpandMoreIcon}
          defaultValue={1000}
          value={tableIncrement}
          input={<OutlinedInput notched={false} label="Reports" />}
          MenuProps={MenuProps}
          onChange={handleChange}
          sx={{
            height: "20px",
            background: "#fff",
            fontSize: "14px",
            marginLeft: "10px",
          }}
        >
          <MenuItem disableRipple={true} disableTouchRipple={true} value={1000}>
            {formatNumber.format(1000)}/page
          </MenuItem>
          <MenuItem
            disableRipple={true}
            disableTouchRipple={true}
            value={10000}
          >
            {formatNumber.format(10000)}/page
          </MenuItem>
          <MenuItem
            disableRipple={true}
            disableTouchRipple={true}
            value={50000}
          >
            {formatNumber.format(50000)}/page
          </MenuItem>
          <MenuItem
            disableRipple={true}
            disableTouchRipple={true}
            value={100000}
          >
            {formatNumber.format(100000)}/page
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default memo(RowsPerPage);
