import React, { memo } from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { selectReportState } from "stateMgmt/reportSlice";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";

import TableInput from "./TableInput/TableInput";

import {
  updateSearchList,
  updateSortedColumn,
  updateSortingDirection,
} from "actions/sessionActions";
import {
  SITE_BY_IM_EXTRACT,
  PSNU_BY_IM,
  MY_DOWNLOADS,
  OU_BY_IM,
  CEE_SCORE,
  CEE_SCORE_DETAIL,
  DATA_REVIEW_TOOL_EXTRACT,
} from "constants/ReportTypes";
import {
  SiteByIMTableProcessed as SiteByIMTable,
  SiteByIMTableFrozenProcessed as SiteByIMTableFrozen,
  MyDownloads,
  OUByIMTable,
  OUByIMTableFrozen,
  PSNUByIMTable,
  PSNUByIMTableFrozen,
  SIMSCEEScoreTable,
  SIMSCEEScoreDetailTable,
  DRTTable,
} from "constants/TableMapping";

const HeaderCell = ({ columnIndex, rowIndex, style }) => {
  const dispatch = useDispatch();
  const currentReportState = useSelector(selectReportState);

  const currentReportName = currentReportState.currentReportName;
  const currentSortedColumn = currentReportState.currentSortedColumn;
  const currentSearchList = currentReportState.currentSearchList;
  const currentSortDirection = currentReportState.currentSortDirection;

  const handleInputChange = (event, field) => {
    // Find changing entry in list, remove it
    const listWithoutEntry = [...currentSearchList];
    if (currentSearchList.length)
      currentSearchList.forEach((entry) => {
        if (entry.field === field)
          listWithoutEntry.splice(listWithoutEntry.indexOf(entry), 1);
      });
    // Update search list
    if (!currentSearchList.length)
      dispatch(updateSearchList([{ field, value: event.target.value }]));
    else {
      event.target.value === ""
        ? dispatch(updateSearchList([...listWithoutEntry]))
        : dispatch(
            updateSearchList([
              ...listWithoutEntry,
              { field, value: event.target.value },
            ])
          );
    }
  };

  const handleMouseClick = (field) => {
    if (currentSortedColumn === field) {
      currentSortDirection === "ascending" &&
        dispatch(updateSortingDirection("descending"));

      currentSortDirection !== "ascending" &&
        dispatch(updateSortingDirection("ascending"));
      currentSortDirection !== "ascending" &&
        dispatch(updateSortedColumn(null));
    } else {
      dispatch(updateSortedColumn(field));
      currentSortDirection === "descending" &&
        dispatch(updateSortingDirection("ascending"));
    }
  };

  let reportMapping;
  const simsIsFrozen = currentReportState.SiteByIMExtract.isFrozen;
  const psnuIsFrozen = currentReportState.PSNUByIMExtract.isFrozen;
  const ouIsFrozen = currentReportState.OUByIMExtract.isFrozen;
  switch (currentReportName) {
    case SITE_BY_IM_EXTRACT:
      reportMapping = simsIsFrozen ? SiteByIMTableFrozen : SiteByIMTable;
      break;
    case PSNU_BY_IM:
      reportMapping = psnuIsFrozen ? PSNUByIMTableFrozen : PSNUByIMTable;
      break;
    case OU_BY_IM:
      reportMapping = ouIsFrozen ? OUByIMTableFrozen : OUByIMTable;
      break;
    case CEE_SCORE:
      reportMapping = SIMSCEEScoreTable;
      break;
    case CEE_SCORE_DETAIL:
      reportMapping = SIMSCEEScoreDetailTable;
      break;
    case DATA_REVIEW_TOOL_EXTRACT:
      reportMapping = DRTTable;
      break;
    case MY_DOWNLOADS:
      reportMapping = MyDownloads;
      break;
  }

  const field = reportMapping[columnIndex].field;
  const doesCurrentFieldExist =
    currentSearchList.length &&
    currentSearchList.filter((item) => field === item.field);
  const currentInputValue =
    doesCurrentFieldExist &&
    doesCurrentFieldExist.length &&
    doesCurrentFieldExist[0].value
      ? doesCurrentFieldExist[0].value
      : "";
  const headerDisplayName = reportMapping[columnIndex].displayName
    ? reportMapping[columnIndex].displayName
    : field.charAt(0).toUpperCase() + field.slice(1);
  const isCellWidthSmall = style.width < 101;
  const ascendingFieldText = isCellWidthSmall ? "Sort: " : "Sort: Ascending";
  const descendingFieldText = isCellWidthSmall ? "Sort: " : "Sort: Descending";

  return (
    <div
      className={clsx(
        "table-cell-header",
        columnIndex + 1 === reportMapping.length && "edge"
      )}
      style={style}
    >
      <div className="table-cell-top">
        <div className="table-cell-top-content-wrap">
          <div className="table-cell-top-text">{headerDisplayName}</div>
        </div>
      </div>
      <div
        className={clsx(
          "table-cell-clickable-area",
          currentSortedColumn === field ? "sorted" : "not-sorted"
        )}
        onClick={() => handleMouseClick(field)}
      >
        <div className="ascending-descending-wrap">
          {currentSortedColumn === field &&
            currentSortDirection === "ascending" && (
              <div className={clsx("ascending-descending-text")}>
                <div className="field-text">{ascendingFieldText}</div>
                <ArrowUpwardRoundedIcon
                  sx={{
                    position: "absolute",
                    right: "-14px",
                    height: "15px",
                    width: "12px",
                    color: "rgb(18, 95, 155)",
                  }}
                />
              </div>
            )}
          {currentSortedColumn === field &&
            currentSortDirection === "descending" && (
              <div className={clsx("ascending-descending-text")}>
                <div className="field-text">{descendingFieldText}</div>
                <ArrowDownwardRoundedIcon
                  sx={{
                    position: "absolute",
                    right: "-14px",
                    height: "15px",
                    width: "12px",
                    color: "rgb(18, 95, 155)",
                  }}
                />
              </div>
            )}
          {currentSortedColumn !== field && (
            <div className={clsx("ascending-descending-text")}>
              <div className="field-text">{ascendingFieldText}</div>
              <ArrowUpwardRoundedIcon
                sx={{
                  position: "absolute",
                  right: "-14px",
                  height: "15px",
                  width: "12px",
                  color: "rgb(18, 95, 155)",
                }}
              />
            </div>
          )}
        </div>
      </div>

      <TableInput
        currentInputValue={currentInputValue}
        handleInputChange={handleInputChange}
        field={field}
      />
    </div>
  );
};

export default memo(HeaderCell);
