import React, { memo, useRef } from "react";
import { SimpleTreeView, TreeItem, useTreeItemState } from "@mui/x-tree-view";
import { useSelector, useDispatch } from "react-redux";
import { selectReportState } from "stateMgmt/reportSlice";
import { selectCurrentState } from "stateMgmt/appSlice";
import {
  selectSessionState,
  updateActiveCountries,
  updateExpanded,
} from "stateMgmt/sessionSlice";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import clsx from "clsx";
import {
  CEE_SCORE,
  CEE_SCORE_DETAIL,
  DATA_REVIEW_TOOL_EXTRACT,
} from "constants/ReportTypes";
import Collapse from "./Tree Icons/Collapse";
import Expand from "./Tree Icons/Expand";
import "./tree.scss";
import logger from "services/LoggingService";

const Tree = () => {
  const dispatch = useDispatch();
  const currentReportState = useSelector(selectReportState);
  const currentAppState = useSelector(selectCurrentState);
  const currentSessionState = useSelector(selectSessionState);

  const top3Levels = currentAppState.top3Levels;
  const currentReportName = currentReportState.currentReportName;
  const expanded = currentAppState.expanded;

  const handleExpanded = (expandedArray) => {
    dispatch(updateExpanded(expandedArray));
  };

  const isSingleSelectionEnabled =
    currentReportName === DATA_REVIEW_TOOL_EXTRACT ||
    currentReportName === CEE_SCORE ||
    currentReportName === CEE_SCORE_DETAIL;
  const selectedNodes = currentSessionState.currentCountriesSelected;
  const hasTopLevelsLoaded = useRef(false);
  let africaData, asiaData, americasData, europeData;

  if (!top3Levels)
    return (
      <div className="tree">
        <div className="loading-tree-text">Loading...</div>
      </div>
    );
  else {
    !hasTopLevelsLoaded.current &&
      !expanded.length &&
      handleExpanded([top3Levels.global.id]);
    hasTopLevelsLoaded.current = true;
    top3Levels.level2.forEach((region) => {
      logger.info("Loading Regions to Tree");
      if (region.label === "Africa") africaData = region;
      else if (region.label === "Asia") asiaData = region;
      else if (region.label === "Americas") americasData = region;
      else if (region.label === "Europe") europeData = region;
    });
  }

  const isGlobalSelected =
    selectedNodes &&
    selectedNodes.length &&
    selectedNodes.includes(top3Levels.global.id);
  const areAllRegionsSelected =
    selectedNodes &&
    selectedNodes.length &&
    selectedNodes.includes(africaData.id) &&
    selectedNodes.includes(asiaData.id) &&
    selectedNodes.includes(americasData.id) &&
    selectedNodes.includes(europeData.id);

  if (areAllRegionsSelected && !isGlobalSelected) {
    logger.info("updateActiveCountries 1");
    dispatch(
      updateActiveCountries([
        top3Levels.global.id,
        africaData.id,
        americasData.id,
        asiaData.id,
        europeData.id,
      ])
    );
  }

  const handleToggle = (event, itemIds) => {
    if (expanded.length === 0) {
      handleExpanded([itemIds]);
    } else {
      let wasFound = false;
      const newArray = expanded.filter((node) => {
        if (node === itemIds) {
          wasFound = true;
          return false;
        } else return true;
      });
      handleExpanded(wasFound ? newArray : [...expanded, itemIds]);
    }
  };

  const handleSelect = (itemIds, parentNodeSelected) => {
    if (isSingleSelectionEnabled) {
      // Single selection
      if (
        selectedNodes &&
        selectedNodes.length &&
        selectedNodes[0] === itemIds
      ) {
        logger.info("updateActiveCountries 2");
        dispatch(updateActiveCountries([]));
      } else {
        logger.info("updateActiveCountries 3");
        dispatch(updateActiveCountries([itemIds]));
      }
    } else if (isGlobalSelected) {
      if (itemIds === top3Levels.global.id) dispatch(updateActiveCountries([]));
      else if (itemIds === africaData.id) {
        logger.info("updateActiveCountries 4");
        dispatch(
          updateActiveCountries([asiaData.id, europeData.id, americasData.id])
        );
      } else if (itemIds === asiaData.id) {
        logger.info("updateActiveCountries 5");
        dispatch(
          updateActiveCountries([africaData.id, europeData.id, americasData.id])
        );
      } else if (itemIds === americasData.id) {
        logger.info("updateActiveCountries 6");
        dispatch(
          updateActiveCountries([asiaData.id, europeData.id, africaData.id])
        );
      } else if (itemIds === europeData.id) {
        logger.info("updateActiveCountries 7");
        dispatch(
          updateActiveCountries([asiaData.id, africaData.id, americasData.id])
        );
      } else {
        if (top3Levels.africaList.includes(itemIds)) {
          const listWithoutNode = top3Levels.africaList.filter(
            (country) => country !== itemIds
          );
          logger.info("updateActiveCountries 8");
          dispatch(
            updateActiveCountries([
              ...listWithoutNode,
              asiaData.id,
              europeData.id,
              americasData.id,
            ])
          );
        } else if (top3Levels.asiaList.includes(itemIds)) {
          const listWithoutNode = top3Levels.asiaList.filter(
            (country) => country !== itemIds
          );
          logger.info("updateActiveCountries 9");
          dispatch(
            updateActiveCountries([
              ...listWithoutNode,
              africaData.id,
              europeData.id,
              americasData.id,
            ])
          );
        } else if (top3Levels.europeList.includes(itemIds)) {
          const listWithoutNode = top3Levels.europeList.filter(
            (country) => country !== itemIds
          );
          logger.info("updateActiveCountries 10");
          dispatch(
            updateActiveCountries([
              ...listWithoutNode,
              asiaData.id,
              africaData.id,
              americasData.id,
            ])
          );
        } else if (top3Levels.americasList.includes(itemIds)) {
          const listWithoutNode = top3Levels.americasList.filter(
            (country) => country !== itemIds
          );
          logger.info("updateActiveCountries 11");
          dispatch(
            updateActiveCountries([
              ...listWithoutNode,
              asiaData.id,
              europeData.id,
              africaData.id,
            ])
          );
        }
      }
    } else if (parentNodeSelected) {
      let removeRegionArray;
      let listWithoutNode;
      let newArray;
      switch (parentNodeSelected) {
        case africaData.id:
          removeRegionArray = selectedNodes.filter(
            (node) =>
              node !== africaData.id && !top3Levels.africaList.includes(node)
          );
          listWithoutNode = top3Levels.africaList.filter(
            (country) => country !== itemIds
          );
          newArray = [...removeRegionArray, ...listWithoutNode];
          logger.info("updateActiveCountries 12");
          dispatch(updateActiveCountries(newArray));
          break;
        case asiaData.id:
          removeRegionArray = selectedNodes.filter(
            (node) =>
              node !== asiaData.id && !top3Levels.asiaList.includes(node)
          );
          listWithoutNode = top3Levels.asiaList.filter(
            (country) => country !== itemIds
          );
          newArray = [...removeRegionArray, ...listWithoutNode];
          logger.info("updateActiveCountries 13");
          dispatch(updateActiveCountries(newArray));
          break;
        case americasData.id:
          removeRegionArray = selectedNodes.filter(
            (node) =>
              node !== americasData.id &&
              !top3Levels.americasList.includes(node)
          );
          listWithoutNode = top3Levels.americasList.filter(
            (country) => country !== itemIds
          );
          newArray = [...removeRegionArray, ...listWithoutNode];
          logger.info("updateActiveCountries 14");
          dispatch(updateActiveCountries(newArray));
          break;
        case europeData.id:
          removeRegionArray = selectedNodes.filter(
            (node) =>
              node !== europeData.id && !top3Levels.europeList.includes(node)
          );
          listWithoutNode = top3Levels.europeList.filter(
            (country) => country !== itemIds
          );
          newArray = [...removeRegionArray, ...listWithoutNode];
          logger.info("updateActiveCountries 15");
          dispatch(updateActiveCountries(newArray));
          break;
      }
    } else {
      // Country selection
      if (selectedNodes.length === 0) {
        logger.info("updateActiveCountries 16");
        dispatch(updateActiveCountries([itemIds]));
      } else {
        let wasFound = false;
        let newArray = selectedNodes.filter((node) => {
          if (node === itemIds) {
            wasFound = true;
            return false;
          } else return true;
        });

        if (wasFound && itemIds === africaData.id) {
          newArray = newArray.filter(
            (item) => !top3Levels.africaList.includes(item)
          );
          logger.info("updateActiveCountries 17");
          dispatch(updateActiveCountries(newArray));
        } else if (wasFound && itemIds === asiaData.id) {
          newArray = newArray.filter(
            (item) => !top3Levels.asiaList.includes(item)
          );
          logger.info("updateActiveCountries 18");
          dispatch(updateActiveCountries(newArray));
        } else if (wasFound && itemIds === americasData.id) {
          newArray = newArray.filter(
            (item) => !top3Levels.americasList.includes(item)
          );
          logger.info("updateActiveCountries 19");
          dispatch(updateActiveCountries(newArray));
        } else if (wasFound && itemIds === europeData.id) {
          newArray = newArray.filter(
            (item) => !top3Levels.europeList.includes(item)
          );
          logger.info("updateActiveCountries 20");
          dispatch(updateActiveCountries(newArray));
        } else {
          logger.info("updateActiveCountries 21");
          dispatch(
            updateActiveCountries(
              wasFound ? newArray : [...selectedNodes, itemIds]
            )
          );
        }
      }
    }
  };

  const CustomContent = React.forwardRef(function CustomContent(props, ref) {
    const {
      classes,
      className,
      label,
      itemId,
      icon: iconProp,
      expansionIcon,
      displayIcon,
      children,
      isSelected,
    } = props;

    const { disabled, expanded, selected, preventSelection } =
      useTreeItemState(itemId);

    let areAllChildrenSelected = true;
    let iterator = 0;
    let isRegionalNode = false;

    if (
      label === "Europe" ||
      label === "Americas" ||
      label === "Africa" ||
      label === "Asia"
    ) {
      if (!isRegionalNode) isRegionalNode = true;
      while (!selected && children[iterator] && areAllChildrenSelected) {
        if (!selectedNodes.includes(children[iterator].id))
          areAllChildrenSelected = false;
        iterator++;
      }
      if (areAllChildrenSelected && !selected && !isSingleSelectionEnabled) {
        handleSelect(itemId, null);
      }
    }
    if (label === "Global") isRegionalNode = true;
    const greyOutCheckbox = isRegionalNode && isSingleSelectionEnabled;

    const icon = iconProp || expansionIcon || displayIcon;
    const handleMouseDown = (event) => {
      preventSelection(event);
    };
    const handleExpansionClick = (event) => {
      handleToggle(event, itemId);
    };
    const handleSelectionClick = (event) => {
      handleSelect(itemId, isSelected);
    };
    return (
      <div
        className={clsx(
          className,
          {
            [classes.expanded]: expanded,

            [classes.disabled]: disabled,
          },
          "tree-item"
        )}
        onMouseDown={handleMouseDown}
        ref={ref}
      >
        <div
          onClick={handleExpansionClick}
          className={clsx("tree-icon-container", !icon && "hideIcon")}
        >
          {expanded ? <Collapse /> : <Expand />}
        </div>

        <div
          className={clsx("tree-label", (selected || isSelected) && "selected")}
          onClick={greyOutCheckbox ? undefined : handleSelectionClick}
        >
          {selected || isSelected ? (
            <CheckBoxIcon
              color="primary"
              sx={{ height: "16px", marginRight: "4px", width: "16px" }}
            />
          ) : (
            <CheckBoxOutlineBlankIcon
              sx={{
                color: greyOutCheckbox ? "rgb(183, 183, 183)" : undefined,
                height: "16px",
                marginRight: "4px",
                width: "16px",
              }}
            />
          )}
          {label}
        </div>
      </div>
    );
  });

  const ChildItem = (props) => {
    return (
      <TreeItem
        ContentComponent={CustomContent}
        {...props}
        itemId={props.ContentProps.id}
        sx={{
          "*:hover": { backgroundColor: "transparent" },
          "> *": { cursor: "auto" },
        }}
      />
    );
  };

  const CustomRegionNode = (props) => {
    return (
      <TreeItem
        ContentComponent={CustomContent}
        {...props}
        itemId={props.ContentProps.id}
        sx={{
          "*:hover": { backgroundColor: "transparent" },
          "> *": { cursor: "auto" },
        }}
      >
        {props.ContentProps.children.map((item) => {
          let isSelectedTmp = selectedNodes.includes(props.ContentProps.id)
            ? props.ContentProps.id
            : null;
          if (isGlobalSelected) isSelectedTmp = top3Levels.global.id;
          // isSelected is null for single selection
          isSelectedTmp = isSingleSelectionEnabled ? null : isSelectedTmp;

          return (
            <ChildItem
              key={item.id}
              ContentProps={{ ...item, isSelected: isSelectedTmp }}
            />
          );
        })}
      </TreeItem>
    );
  };

  return (
    <div className="tree">
      <SimpleTreeView
        aria-label="operatingUnitTree"
        // defaultCollapseIcon={<Collapse />}
        // defaultExpandIcon={<Expand />}
        slots={({ expandIcon: Expand }, { collapseIcon: Collapse })}
        expandedItems={expanded}
        selectedItems={selectedNodes}
        sx={{ marginBottom: "25px" }}
      >
        <TreeItem
          ContentComponent={CustomContent}
          ContentProps={{ isSelected: null }}
          itemId={top3Levels.global.id}
          label="Global"
          sx={{
            [`& .muiTreeItem-content`]: { padding: 0 },
          }}
        >
          {top3Levels.level2.map((item) => {
            // isSelected is null for single selection
            const isSelectedTmp = isSingleSelectionEnabled
              ? null
              : isGlobalSelected;

            return (
              <CustomRegionNode
                key={item.id}
                ContentProps={{ ...item, isSelected: isSelectedTmp }}
              />
            );
          })}
        </TreeItem>
      </SimpleTreeView>
    </div>
  );
};

export default memo(Tree);
