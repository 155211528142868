import React, { memo } from 'react';

const CollapseIcon = () => (
  <svg version="1.1" id="collapse-icon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="0 0 50 50">
  <g>
    <path className="st0" d="M5.2,47.3c-1.3,0-2.4-1.1-2.4-2.4V5.2c0-1.3,1.1-2.4,2.4-2.4h39.7c1.3,0,2.4,1.1,2.4,2.4v39.7
    c0,1.3-1.1,2.4-2.4,2.4H5.2z"/>
    <path className="st1" d="M44.5,5.5v39h-39v-39H44.5 M44.8,0H5.2C2.3,0,0,2.3,0,5.2v39.7C0,47.7,2.3,50,5.2,50h39.7
    c2.8,0,5.2-2.3,5.2-5.2V5.2C50,2.3,47.7,0,44.8,0L44.8,0z"/>
  </g>
  <g>
    <rect x="11.3" y="22.8" className="st1" width="27.5" height="4.5"/>
  </g>
  </svg>
)

export default memo(CollapseIcon);