import axios from "axios";
import { BASE_URL } from "constants/Session";

export const axiosToken = () => {
  const axiosInstance = axios.create({
    types: ["SUCCESS", "FAILURE"],
    baseURL: "/",
    timeout: 60000,
    headers: { "Content-Type": "text/plain" },
  });

  return axiosInstance;
};

export const axiosSession = (url) => {
  const axiosInstance = axios.create({
    baseURL: BASE_URL[`document.location.hostname/${url}`],
    timeout: 60000,
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  });

  return axiosInstance;
};

export const axiosFilterSession = (url) => {
  const axiosInstance = axios.create({
    types: ["SUCCESS", "PENDING", "FAILURE"],
    baseURL: url,
    timeout: 60000,
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
  });

  return axiosInstance;
};

export const axiosWithParams = (url, params) => {
  const axiosInstance = axios.create({
    baseURL: BASE_URL[`document.location.hostname/${url}`],
    timeout: 60000,
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    params,
  });

  return axiosInstance;
};
