import { generateOUString } from "constants/FilterURLs/generateOUString";
import { saveAs } from "file-saver";
import {
  SITE_BY_IM_EXTRACT,
  PSNU_BY_IM,
  OU_BY_IM,
  CEE_SCORE,
  CEE_SCORE_DETAIL,
  DATA_REVIEW_TOOL_EXTRACT,
} from "constants/ReportTypes";
import {
  // LOAD_TOP_3_LEVELS,
  // UPDATE_ACTIVE_COUNTRIES,
  FETCH_TEXT_EXPORT,
  LOAD_FILE,
  UPDATE_REPORT_TYPE,
  LOAD_CURRENT_TABLE,
  BASE_URL,
} from "constants/Session";
import logger from "services/LoggingService";

export default ({ getState }) =>
  (next) =>
  async (action) => {
    // debugger;
    logger.debug(`Session Middleware called with type: ${action.type}`);
    if (action.type === "appState/init/fulfilled") {
      // Process top 3 levels data
      const globalData = {
        global: {
          label: action.payload.data[0].label,
          id: action.payload.data[0].id,
          leafData: action.payload.data[0].leafData,
        },
        level2: [],
        africaList: [],
        asiaList: [],
        europeList: [],
        americasList: [],
      };
      action.payload.data[0].children.forEach((region) => {
        globalData.level2.push(region);

        region.children.forEach((country) => {
          if (region.label === "Africa") globalData.africaList.push(country.id);
          else if (region.label === "Americas")
            globalData.americasList.push(country.id);
          else if (region.label === "Asia")
            globalData.asiaList.push(country.id);
          else if (region.label === "Europe")
            globalData.europeList.push(country.id);
        });
      });
      action.payload.data.globalData = globalData;
    }

    if (
      action.type === LOAD_CURRENT_TABLE.SUCCESS ||
      action.type === LOAD_CURRENT_TABLE.FAILURE
    ) {
      const top3Levels = getState().appState.top3Levels;
      const selectedCountryNames = getState().sessionState.selectedCountryNames;
      const allCountryNames = generateOUString(
        selectedCountryNames,
        top3Levels
      );
      action.allCountryNames = allCountryNames;
    }

    if (action.type === LOAD_CURRENT_TABLE.PENDING) {
      const urlLength =
        BASE_URL[document.location.hostname].length +
        action.payload.request.url.length;
      if (urlLength > 2000) action.alert = urlLength;
    }

    if (action.type === UPDATE_REPORT_TYPE) {
      // Save current country details, load report type country details

      // Load values
      switch (action.payload.reportType) {
        case SITE_BY_IM_EXTRACT:
          action.updatedCurrentCountriesSelected =
            getState().sessionState.currentCountriesSelectedSIMS;
          action.updatedSelectedCountryNames =
            getState().sessionState.selectedCountryNamesSIMS;
          action.updatedCurrentCountryUIDString =
            getState().sessionState.currentCountryUIDStringSIMS;
          action.updatedExpanded = getState().sessionState.expandedSIMS;
          break;
        case PSNU_BY_IM:
          action.updatedCurrentCountriesSelected =
            getState().sessionState.currentCountriesSelectedPSNU;
          action.updatedSelectedCountryNames =
            getState().sessionState.selectedCountryNamesPSNU;
          action.updatedCurrentCountryUIDString =
            getState().sessionState.currentCountryUIDStringPSNU;
          action.updatedExpanded = getState().sessionState.expandedPSNU;
          break;
        case OU_BY_IM:
          // action.updatedOURadiosSelected =
          //   getState().sessionState.currentSelectedOURadios;
          action.updatedCurrentCountriesSelected =
            getState().sessionState.currentCountriesSelectedOU;
          action.updatedSelectedCountryNames =
            getState().sessionState.selectedCountryNamesOU;
          action.updatedCurrentCountryUIDString =
            getState().sessionState.currentCountryUIDStringOU;
          action.updatedExpanded = getState().sessionState.expandedOU;
          break;
        case CEE_SCORE:
          action.updatedCurrentCountriesSelected =
            getState().sessionState.currentCountriesSelectedCeeScore;
          action.updatedSelectedCountryNames =
            getState().sessionState.selectedCountryNamesCeeScore;
          action.updatedCurrentCountryUIDString =
            getState().sessionState.currentCountryUIDStringCeeScore;
          action.updatedExpanded = getState().sessionState.expandedCEEScore;
          break;
        case CEE_SCORE_DETAIL:
          action.updatedCurrentCountriesSelected =
            getState().sessionState.currentCountriesSelectedCeeScoreDetail;
          action.updatedSelectedCountryNames =
            getState().sessionState.selectedCountryNamesCeeScoreDetail;
          action.updatedCurrentCountryUIDString =
            getState().sessionState.currentCountryUIDStringCeeScoreDetail;
          action.updatedExpanded =
            getState().sessionState.expandedCEEScoreDetail;
          break;
        case DATA_REVIEW_TOOL_EXTRACT:
          action.updatedCurrentCountriesSelected =
            getState().sessionState.currentCountriesSelectedDRT;
          action.updatedSelectedCountryNames =
            getState().sessionState.selectedCountryNamesDRT;
          action.updatedCurrentCountryUIDString =
            getState().sessionState.currentCountryUIDStringDRT;
          action.updatedExpanded = getState().sessionState.expandedDRT;
          break;
      }

      // Save values
      const previousReportType = getState().currentReportName;
      switch (previousReportType) {
        case SITE_BY_IM_EXTRACT:
          action.currentCountriesSelectedSIMS =
            getState().sessionState.currentCountriesSelected;
          action.selectedCountryNamesSIMS =
            getState().sessionState.selectedCountryNames;
          action.currentCountryUIDStringSIMS =
            getState().sessionState.currentCountryUIDString;
          action.expandedSIMS = getState().sessionState.expanded;
          break;
        case PSNU_BY_IM:
          action.currentCountriesSelectedPSNU =
            getState().sessionState.currentCountriesSelected;
          action.selectedCountryNamesPSNU =
            getState().sessionState.selectedCountryNames;
          action.currentCountryUIDStringPSNU =
            getState().sessionState.currentCountryUIDString;
          action.expandedPSNU = getState().sessionState.expanded;
          break;
        case OU_BY_IM:
          action.currentCountriesSelectedOU =
            getState().sessionState.currentCountriesSelected;
          action.selectedCountryNamesOU =
            getState().sessionState.selectedCountryNames;
          action.currentCountryUIDStringOU =
            getState().sessionState.currentCountryUIDString;
          action.expandedOU = getState().sessionState.expanded;
          break;
        case CEE_SCORE:
          action.currentCountriesSelectedCeeScore =
            getState().sessionState.currentCountriesSelected;
          action.selectedCountryNamesCeeScore =
            getState().sessionState.selectedCountryNames;
          action.currentCountryUIDStringCeeScore =
            getState().sessionState.currentCountryUIDString;
          action.expandedCEEScore = getState().sessionState.expanded;
          break;
        case CEE_SCORE_DETAIL:
          action.currentCountriesSelectedCeeScoreDetail =
            getState().sessionState.currentCountriesSelected;
          action.selectedCountryNamesCeeScoreDetail =
            getState().sessionState.selectedCountryNames;
          action.currentCountryUIDStringCeeScoreDetail =
            getState().sessionState.currentCountryUIDString;
          action.expandedCEEScoreDetail = getState().sessionState.expanded;
          break;
        case DATA_REVIEW_TOOL_EXTRACT:
          action.currentCountriesSelectedDRT =
            getState().sessionState.currentCountriesSelected;
          action.selectedCountryNamesDRT =
            getState().sessionState.selectedCountryNames;
          action.currentCountryUIDStringDRT =
            getState().sessionState.currentCountryUIDString;
          action.expandedDRT = getState().sessionState.expanded;
          break;
      }
    }

    // Save text file
    if (action.type === FETCH_TEXT_EXPORT.SUCCESS) {
      const fileName = getState().sessionState.textFileName;
      const blob = new Blob([action.payload.data], {
        type: "application/octet-stream",
      });
      saveAs(blob, fileName);
    }

    // Save zip file (from my downloads)
    if (action.type === LOAD_FILE.SUCCESS) {
      const fileName = getState().sessionState.downloadFileName;
      const blob = new Blob([action.payload.data], {
        type: "application/octet-stream",
      });
      saveAs(blob, fileName);
    }

    if (action.type === "updateActiveCountries") {
      // Go through array, create uidHierarchy string, create selectedcountrynames array
      const selectedCountryNames = [];
      const globalInfo = getState().appState.top3Levels;
      let africaInfo, americasInfo, asiaInfo, europeInfo;

      const globalObject = {};
      const countryIDs = {};
      const regionIDs = {};
      let globalID = "";

      globalInfo.level2.forEach((region) => {
        globalObject[region.id] = region;
        if (region.label === "Africa") africaInfo = region;
        else if (region.label === "Asia") asiaInfo = region;
        else if (region.label === "Americas") americasInfo = region;
        else if (region.label === "Europe") europeInfo = region;
      });
      action.payload.countryList &&
        action.payload.countryList.length &&
        action.payload.countryList.forEach((country, index) => {
          let isFound = false;
          if (country === globalInfo.global.id) {
            // Add global to globalString
            const globalString =
              "uidHierarchy=" + globalInfo.global.leafData.uidlevel1;
            isFound = true;
            selectedCountryNames.push("Global");
            globalID = globalString;
          }

          !isFound &&
            globalInfo.level2.forEach((region) => {
              if (region.id === country) {
                // push to region array
                const regionUID =
                  "uidHierarchy=" +
                  region.leafData.uidlevel1 +
                  "%7C" +
                  region.leafData.uidlevel2;
                isFound = true;
                selectedCountryNames.push(region.label);
                regionIDs[region.id] = regionUID;
              }
            });

          const createCountryUID = (regionArray, country) => {
            let uid = "uidHierarchy=";
            // Search through region
            regionArray.children.forEach((item) => {
              if (item.id === country) {
                uid +=
                  item.leafData.uidlevel1 +
                  "%7C" +
                  item.leafData.uidlevel2 +
                  "%7C" +
                  item.leafData.uidlevel3;
                selectedCountryNames.push(item.label);
                countryIDs[item.id] = uid;
              }
            });
          };

          if (!isFound && globalInfo.africaList.includes(country)) {
            createCountryUID(africaInfo, country);
          } else if (!isFound && globalInfo.asiaList.includes(country)) {
            createCountryUID(asiaInfo, country);
          } else if (!isFound && globalInfo.europeList.includes(country)) {
            createCountryUID(europeInfo, country);
          } else if (!isFound && globalInfo.americasList.includes(country)) {
            createCountryUID(americasInfo, country);
          }
        });

      const uidArray = [];
      const arrayOfCountryIDs = Object.keys(countryIDs);
      const arrayOfRegionIDs = Object.keys(regionIDs);
      if (globalID) uidArray.push(globalID);
      else {
        const regions = Object.keys(regionIDs);
        if (regions.length)
          regions.forEach((item) => uidArray.push(regionIDs[item]));
      }

      !globalID &&
        arrayOfCountryIDs.length &&
        arrayOfCountryIDs.forEach((country, index) => {
          let isFound = false;
          arrayOfRegionIDs.length &&
            arrayOfRegionIDs.forEach((item) => {
              globalObject[item].children.forEach((i) => {
                if (i.id === country) isFound = true;
              });
            });
          if (!isFound) {
            uidArray.push(countryIDs[country]);
          }
        });

      const uidString = uidArray.reverse().join("&");

      action.payload.uidString = uidString;
      action.payload.selectedCountryNames = selectedCountryNames;
    } // end of update countries

    return next(action);
  };
