import React, {
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  createRef,
  useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectReportState } from "stateMgmt/reportSlice";
import { selectCurrentState } from "stateMgmt/appSlice";
import { FixedSizeGrid, VariableSizeGrid } from "react-window";
import clsx from "clsx";
import { animated, useSpring } from "react-spring";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CancelIcon from "@mui/icons-material/Cancel";
import { CircularProgress } from "@mui/material";

import ParameterBox from "../ParameterBox/ParameterBox";
import PaginationBar from "./PaginationBar/PaginationBar";
import RowsPerPage from "./RowsPerPage/RowsPerPage";
import HeaderCell from "./HeaderCell";
import MyDownloadsTable from "./MyDownloadsTable/MyDownloadsTable";
import { determineOUShorthand } from "constants/determineOUShorthand";

import {
  SiteByIMTableProcessed as SiteByIMTable,
  SiteByIMTableFrozenProcessed as SiteByIMTableFrozen,
  MyDownloads,
  PSNUByIMTable,
  PSNUByIMTableFrozen,
  OUByIMTable,
  OUByIMTableFrozen,
  SIMSCEEScoreTable,
  SIMSCEEScoreDetailTable,
  DRTTable,
} from "constants/TableMapping";
import {
  SITE_BY_IM_EXTRACT,
  PSNU_BY_IM,
  OU_BY_IM,
  CEE_SCORE,
  CEE_SCORE_DETAIL,
  MY_DOWNLOADS,
  DATA_REVIEW_TOOL_EXTRACT,
} from "constants/ReportTypes";
import {
  updateSearchList,
  updateSortedColumn,
  updateSortingDirection,
  cancelFile,
} from "actions/sessionActions";
import { BASE_URL } from "constants/Session";

const ReportTable = () => {
  const dispatch = useDispatch();
  const currentReportState = useSelector(selectReportState);
  const currentAppState = useSelector(selectCurrentState);
  const currentTableData = currentReportState.currentTableData;
  const [filteredAndSortedTableData, setFilteredAndSortedTableData] = useState(
    []
  );

  const currentReportName = currentReportState.currentReportName;
  const top3Levels = currentAppState.top3Levels;
  const isAuthenticated = currentAppState.isAuthenticated;
  const isTablePageLoading = currentReportState.isTablePageLoading;
  const currentTableIncrement = currentReportState.currentTableIncrement;
  const currentTablePage = currentReportState.currentTablePage;
  const currentReportCount = currentReportState.currentReportCount;
  const currentParameterCount = currentReportState.currentParameterCount;
  const isMyDownloadsLoading = currentReportState.isMyDownloadsLoading;
  const currentTableWidth = currentReportState.currentTableWidth;

  const [currentTableWidthWithMax, setCurrentTableWidthWithMax] =
    useState(currentTableWidth);

  const hasTableLoadingFailed = currentReportState.hasTableLoadingFailed;

  const currentSearchList = currentReportState.currentSearchList;
  const currentSortedColumn = currentReportState.currentSortedColumn;
  const currentSortDirection = currentReportState.currentSortDirection;

  const tableFirstRenderRef = useRef(false);
  const checkCount = createRef();

  const [isParameterFilterBoxOpen, setParameterFilterBox] = useState(true);

  let reportMapping;
  const simsIsFrozen = currentReportState.SiteByIMExtract.isFrozen;
  const psnuIsFrozen = currentReportState.PSNUByIMExtract.isFrozen;
  const ouIsFrozen = currentReportState.OUByIMExtract.isFrozen;
  switch (currentReportName) {
    case SITE_BY_IM_EXTRACT:
      reportMapping = simsIsFrozen ? SiteByIMTableFrozen : SiteByIMTable;
      break;
    case PSNU_BY_IM:
      reportMapping = psnuIsFrozen ? PSNUByIMTableFrozen : PSNUByIMTable;
      break;
    case OU_BY_IM:
      reportMapping = ouIsFrozen ? OUByIMTableFrozen : OUByIMTable;
      break;
    case CEE_SCORE:
      reportMapping = SIMSCEEScoreTable;
      break;
    case CEE_SCORE_DETAIL:
      reportMapping = SIMSCEEScoreDetailTable;
      break;
    case DATA_REVIEW_TOOL_EXTRACT:
      reportMapping = DRTTable;
      break;
    case MY_DOWNLOADS:
      reportMapping = MyDownloads;
      break;
  }

  useLayoutEffect(() => {
    // New Page, reset page filters
    if (tableFirstRenderRef.current && currentTableData) {
      setFilteredAndSortedTableData([...currentTableData]);
      dispatch(updateSearchList([]));
      dispatch(updateSortingDirection("ascending"));
      dispatch(updateSortedColumn(null));
    } else tableFirstRenderRef.current = true;
  }, [currentTableData]);

  useLayoutEffect(() => {
    // Check if window width > currentTableWidth; set max
    const bottomTable = document.getElementsByClassName("table-grid");
    currentTableWidth &&
      bottomTable &&
      bottomTable[0] &&
      setCurrentTableWidthWithMax(
        currentTableWidth > bottomTable[0].scrollWidth
          ? bottomTable[0].scrollWidth
          : currentTableWidth
      );
  });

  // watch for search, sort changes
  useLayoutEffect(() => {
    if (currentTableData && currentTableData.length) {
      let newFilteredSortedList = [...currentTableData];
      // Check for filtering from any table header
      if (currentSearchList.length) {
        newFilteredSortedList = newFilteredSortedList.filter((row) => {
          let isValid = true;
          currentSearchList.forEach((columnFilter) => {
            const currentItemUpperCase =
              row[columnFilter.field] &&
              `${row[columnFilter.field]}`.toUpperCase();
            const columnFilterUpperCase = columnFilter.value.toUpperCase();
            if (
              !row[columnFilter.field] ||
              !currentItemUpperCase.includes(columnFilterUpperCase)
            )
              isValid = false;
            if (currentItemUpperCase === "0" && columnFilterUpperCase === "0")
              isValid = true; // handle use case w/ zero
          });
          return isValid;
        });
      }

      if (
        currentSortedColumn &&
        currentSortedColumn === "FileName" &&
        currentReportName === MY_DOWNLOADS
      ) {
        const statusOrder = {
          Cancelled: 4,
          Completed: 1,
          Pending: 2,
          Processing: 3,
        };
        const nullValues = [];
        let listWithValues = [];
        newFilteredSortedList.forEach((item) => {
          if (!item[currentSortedColumn]) nullValues.push(item);
          else listWithValues.push(item);
        });
        listWithValues = listWithValues.sort((a, b) => {
          if (currentSortDirection === "ascending") {
            return statusOrder[`${a.Status}`] > statusOrder[`${b.Status}`];
          } else {
            return statusOrder[`${b.Status}`] > statusOrder[`${a.Status}`];
          }
        });
        newFilteredSortedList =
          currentSortDirection === "ascending"
            ? [...listWithValues, ...nullValues]
            : [...nullValues, ...listWithValues];
      } else if (
        currentSortedColumn &&
        currentSortedColumn === "RequestedTimeRelative" &&
        currentReportName === MY_DOWNLOADS
      ) {
        const nullValues = [];
        let listWithValues = [];
        newFilteredSortedList.forEach((item) => {
          if (!item[currentSortedColumn]) nullValues.push(item);
          else listWithValues.push(item);
        });
        listWithValues = listWithValues.sort((a, b) => {
          const firstDate = new Date(a.RequestedTime);
          const secondDate = new Date(b.RequestedTime);
          return firstDate.getTime() > secondDate.getTime();
        });
        newFilteredSortedList =
          currentSortDirection === "ascending"
            ? [...listWithValues, ...nullValues]
            : [...nullValues, ...listWithValues.reverse()];
      } else if (currentSortedColumn !== null) {
        // sort by asc/desc; put null values at end of list(or beginning if desc)
        const nullValues = [];
        let listWithValues = [];
        newFilteredSortedList.forEach((item) => {
          if (!item[currentSortedColumn]) nullValues.push(item);
          else listWithValues.push(item);
        });
        listWithValues = listWithValues.sort((a, b) => {
          // Handle negative numbers
          if (
            `${a[currentSortedColumn]}`.charAt(0) === `-` &&
            `${a[currentSortedColumn]}`.charAt(1) >= "0" &&
            `${a[currentSortedColumn]}`.charAt(1) <= "9" &&
            `${b[currentSortedColumn]}`.charAt(0) === `-` &&
            `${b[currentSortedColumn]}`.charAt(1) >= "0" &&
            `${b[currentSortedColumn]}`.charAt(1) <= "9"
          )
            return `${b[currentSortedColumn]}`.localeCompare(
              `${a[currentSortedColumn]}`,
              "en",
              { numeric: true }
            );

          // Special case: OperatingUnit for My Downloads
          if (
            currentSortedColumn === "OperatingUnit" &&
            currentReportName === MY_DOWNLOADS
          ) {
            const aCountryArray = `${a[currentSortedColumn]}`.split(", ");
            const bCountryArray = `${b[currentSortedColumn]}`.split(", ");
            const finalAValue = determineOUShorthand(aCountryArray, top3Levels);
            const finalBValue = determineOUShorthand(bCountryArray, top3Levels);

            if (currentSortDirection === "ascending") {
              return `${finalAValue}`.localeCompare(`${finalBValue}`, "en", {
                numeric: true,
              });
            } else
              return `${finalBValue}`.localeCompare(`${finalAValue}`, "en", {
                numeric: true,
              });
          }

          if (currentSortDirection === "ascending") {
            return `${a[currentSortedColumn]}`.localeCompare(
              `${b[currentSortedColumn]}`,
              "en",
              { numeric: true }
            );
          } else {
            return `${b[currentSortedColumn]}`.localeCompare(
              `${a[currentSortedColumn]}`,
              "en",
              { numeric: true }
            );
          }
        });
        newFilteredSortedList =
          currentSortDirection === "ascending"
            ? [...listWithValues, ...nullValues]
            : [...nullValues, ...listWithValues];
      }

      setFilteredAndSortedTableData(newFilteredSortedList);
    }
  }, [currentSearchList, currentSortDirection, currentSortedColumn]);

  const columnWidth = 150;
  const headerRow = createRef();
  let totalItems =
    currentTableIncrement > currentReportCount
      ? currentReportCount
      : currentTableIncrement;
  const lastPageItemCount = currentReportCount % currentTableIncrement;
  const isLastPage =
    currentTableIncrement * currentTablePage - currentReportCount > 0;
  if (lastPageItemCount < totalItems && isLastPage)
    totalItems = lastPageItemCount;

  const Cell = ({ columnIndex, rowIndex, style }) => {
    const [buttonClickedID, setButtonClickedID] = useState(null);
    const isCellRowEven = rowIndex % 2 === 0;
    let fieldString =
      filteredAndSortedTableData &&
      filteredAndSortedTableData[rowIndex] &&
      filteredAndSortedTableData[rowIndex][reportMapping[columnIndex].field]
        ? filteredAndSortedTableData[rowIndex][reportMapping[columnIndex].field]
        : "";

    const handleDownloadButtonClick = () => {
      const fileName = filteredAndSortedTableData[rowIndex].FileName;
      setButtonClickedID(filteredAndSortedTableData[rowIndex].JobId);

      const fileURL =
        BASE_URL[document.location.hostname] +
        "/api/Download/GetFile?fileName=" +
        fileName;
      const a = document.createElement("a");
      a.href = fileURL;
      a.download = fileName;
      a.click();
    };
    const handleCancelButtonClick = () => {
      const jobID = filteredAndSortedTableData[rowIndex].JobId;
      const HangfireJobId = filteredAndSortedTableData[rowIndex].HangfireJobId;
      const RequestedBy = filteredAndSortedTableData[rowIndex].RequestedBy;
      setButtonClickedID(filteredAndSortedTableData[rowIndex].JobId);
      dispatch(cancelFile(jobID, HangfireJobId, RequestedBy));
    };

    /*
      Field processing
    */
    // Filesize in KB
    if (
      currentReportName === MY_DOWNLOADS &&
      filteredAndSortedTableData &&
      reportMapping[columnIndex].field === "FileSizeInKb"
    ) {
      if (!fieldString) fieldString = "";
      else
        fieldString =
          typeof fieldString === "number"
            ? parseInt(fieldString).toLocaleString("en-US") + " KB"
            : fieldString.toLocaleString("en-US") + " KB";
    }
    // Operating Unit
    if (
      currentReportName === MY_DOWNLOADS &&
      filteredAndSortedTableData &&
      reportMapping[columnIndex].field === "OperatingUnit"
    ) {
      const arrayOfCountries =
        filteredAndSortedTableData.length &&
        filteredAndSortedTableData[rowIndex].OperatingUnit
          ? filteredAndSortedTableData[rowIndex].OperatingUnit.split(", ")
          : null;
      if (arrayOfCountries && arrayOfCountries.length > 1)
        fieldString = determineOUShorthand(arrayOfCountries, top3Levels);
    }
    // Approval Level
    if (
      filteredAndSortedTableData &&
      (reportMapping[columnIndex].field === "approvallevel" ||
        reportMapping[columnIndex].field === "approval_level")
    ) {
      if (fieldString === "") fieldString = 0;
    }

    const isFileNameAndCompleted =
      reportMapping[columnIndex].field === "FileName" &&
      filteredAndSortedTableData &&
      filteredAndSortedTableData[rowIndex].Status === "Completed";
    const isFileNameAndCancelled =
      reportMapping[columnIndex].field === "FileName" &&
      filteredAndSortedTableData &&
      filteredAndSortedTableData[rowIndex].Status === "Cancelled";
    const isFileName =
      reportMapping[columnIndex].field === "FileName" &&
      filteredAndSortedTableData &&
      filteredAndSortedTableData[rowIndex].Status;
    const isDownloadsLoading = isFileNameAndCompleted
      ? useSelector((state) => state.sessionState.isMyDownloadsFileLoading)
      : null;
    const isDownloadsCancelling = isFileName
      ? useSelector((state) => state.sessionState.isMyDownloadsCancelLoading)
      : null;
    const isButtonClicked =
      buttonClickedID === filteredAndSortedTableData[rowIndex].JobId;
    useLayoutEffect(() => {
      if (buttonClickedID && !isDownloadsLoading) setButtonClickedID(null);
    }, [isDownloadsLoading]);
    useLayoutEffect(() => {
      if (buttonClickedID && !isDownloadsCancelling) setButtonClickedID(null);
    }, [isDownloadsCancelling]);
    const reportTableCellStyle = clsx(
      "report-table-cell",
      !isCellRowEven && "even-cell",
      columnIndex + 1 === reportMapping.length && "edge",
      rowIndex + 1 === filteredAndSortedTableData.length && "last-row"
    );
    if (isFileNameAndCompleted)
      return (
        <div
          className={reportTableCellStyle}
          onClick={handleDownloadButtonClick}
          style={style}
        >
          <div className="report-table-cell-icon">
            {isDownloadsLoading && isButtonClicked ? (
              <CircularProgress size={17} />
            ) : (
              <CloudDownloadIcon sx={{ color: "#276696" }} />
            )}
          </div>
        </div>
      );
    else if (isFileNameAndCancelled)
      return (
        <div className={reportTableCellStyle} style={style}>
          <div className="report-table-cell-icon cancelled">
            <CancelIcon sx={{ color: "#808080" }} />
          </div>
        </div>
      );
    else if (isFileName)
      return (
        <div
          className={reportTableCellStyle}
          onClick={handleCancelButtonClick}
          style={style}
        >
          <div className="report-table-cell-icon">
            {isDownloadsCancelling && isButtonClicked ? (
              <CircularProgress size={17} />
            ) : (
              <CancelIcon sx={{ color: "#E60000" }} />
            )}
          </div>
        </div>
      );
    else
      return (
        <div className={reportTableCellStyle} style={style}>
          <div className="report-table-cell-text">{fieldString}</div>
        </div>
      );
  };

  const [lowerBottomPortionAnimation, activateLowerBottomPortionAnimation] =
    useSpring(() => ({
      translateY: `0px`,
    }));

  useEffect(() => {
    activateLowerBottomPortionAnimation.start({
      to: {
        translateY: isParameterFilterBoxOpen
          ? `0px`
          : `-${20 + currentParameterCount * 16}px`,
      },
      config: { duration: checkCount.current ? 0 : undefined },
    });
  }, [isParameterFilterBoxOpen, currentParameterCount]);

  useLayoutEffect(() => {
    // This determines if the animation duration needs to be immediate or not
    checkCount.current = currentParameterCount;
  }, [currentParameterCount]);

  const bottomTable = document.getElementsByClassName("table-grid");
  const headerRowScrollbarOffset =
    bottomTable &&
    bottomTable[0] &&
    bottomTable[0].offsetWidth - bottomTable[0].clientWidth > 0
      ? bottomTable[0].offsetWidth - bottomTable[0].clientWidth
      : 0;
  if (!currentTableData && currentReportName === CEE_SCORE) {
    return (
      <>
        <p>
          {`This report includes SIMS assessments since FY2016 Q2 to FY19 Q1 for SIMS 2.0 / 3.0 and, starting 
        at the end of the FY19 Q3 cleaning period, SIMS 4.0.`}
        </p>
        <p>
          {`Note: Currently, if a user selects any period after FY19 Q2 (i.e. when SIMS 4.0 implementation began), 
        no data will be returned. This is because SIMS 4.0 data will not be available in agency-based data systems 
        and imported into DATIM until the FY19 Q3 Cleaning period has closed (i.e. after September 23, 2019, as per 
        the PEPFAR Data Calendar).`}
        </p>
        <p>
          {`The report shows the total Number of Assessments for the Country, Funding Agency + Mechanism and SIMS Core 
        Essential Element (CEE). The total Number of Assessments includes all assessments with Scores of Red (1), Yellow 
        (2) and Green (3) but does not include Scores of Not Applicable (Score 0).`}
        </p>
      </>
    );
  } else if (!currentTableData && currentReportName === CEE_SCORE_DETAIL) {
    return (
      <>
        <p>
          {`This report includes SIMS assessments only for SIMS 4.0 (which started in FY2019 Q2). This report 
        will be available at the end of the FY19 Q3 Cleaning period (i.e. after September 27, 2019, as per
           the PEPFAR Data Calendar)`}
        </p>
        <p>
          {`Note: Currently, if a user selects any period after FY19 Q2 (i.e. when SIMS 4.0 implementation began), 
        no data will be returned. This is because SIMS 4.0 data will only be available in agency-based data 
        systems and imported into DATIM after the FY19 Q3 Cleaning period has closed (i.e. after September 27, 2019).`}
        </p>
        <p>
          {`The report shown on this page provides details on CEE Score details by Country, Site, Funding Agency 
        + Mechanism. The report will only be a summary of what is available in the downloadable version. In 
        the download, the Microsoft Excel file will contain two pivot tables. The first pivot table can be modified 
        to show Organisation Site level information. The second pivot table shows question and answer detail 
        by the SIMS Core Essential Element (CEE).`}
        </p>
        <p>
          {`This report provides details about The total Number of Assessments column includes all assessments 
        with Scores of Red (1), Yellow (2) and Green (3) but does not include Scores of Not Applicable (Score 0).`}
        </p>
      </>
    );
  } else if (currentReportName === MY_DOWNLOADS) {
    return (
      <>
        {(currentTableData && currentTableData.length) ||
        isMyDownloadsLoading ? (
          <MyDownloadsTable
            isAuthenticated={isAuthenticated}
            reportMapping={reportMapping}
            Cell={Cell}
            HeaderCell={HeaderCell}
            filteredAndSortedTableData={filteredAndSortedTableData}
          />
        ) : (
          <div className="downloads-table-empty-text">
            Your list of downloads is currently empty
          </div>
        )}
      </>
    );
  } else if (!currentTableData) {
    return (
      <>
        <p>
          This tool will enable you to pull OU data from DATIM in an easy and
          intuitive manner.
        </p>
        {currentReportName === SITE_BY_IM_EXTRACT && (
          <p>To enable filtering, select a third-level operating unit.</p>
        )}

        <p>{`Using the filters on the left will help to refine the search results. In the "table view" you can 
        filter within the table, sort and hide columns, and export to a .csv file 
        providing you with greater access and ability to analyze the data.`}</p>
        <p>
          Once you have refined the reporting filters as desired, click
          &quot;Run Report&quot; to see the data returned in the main table.
        </p>

        {currentReportName !== DATA_REVIEW_TOOL_EXTRACT && (
          <>
            <p className="report-bold-text">{`Update: Users can now select multiple OUs in the Operating Unit filter. 
          Click any OU to select it, and click again to de-select it. Please 
          exercise caution in selecting many OUs, as this may result in large datasets that 
          take additional time to run.`}</p>
            <p className="report-bold-text">{`Note for Excel users: Although there is no longer a maximum row limit for Genie downloads, 
          please keep in mind that Excel does contain a limit of approximately 1 million rows.`}</p>
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        {filteredAndSortedTableData && reportMapping ? (
          <ParameterBox
            isParameterFilterBoxOpen={isParameterFilterBoxOpen}
            setParameterFilterBox={setParameterFilterBox}
          />
        ) : null}
        {filteredAndSortedTableData &&
        currentTableData.length !== 0 &&
        reportMapping ? (
          <animated.div
            className="report-table-bottom"
            style={{
              ...lowerBottomPortionAnimation,
              zIndex: isAuthenticated ? "5" : "auto",
            }}
          >
            {currentReportCount > 1048576 && (
              <div
                className="excel-warning"
                style={{ zIndex: isAuthenticated ? "6" : "auto" }}
              >{`Please note that the selected data set is larger than 1,048,576 rows, which exceeds the row limit 
          for Excel v2007 or later. If you are planning to analyze this data in Excel, the data will be truncated. 
          Please consider using Genie's filters to make a smaller data set.`}</div>
            )}
            <PaginationBar isAuthenticated={isAuthenticated} />
            <RowsPerPage isAuthenticated={isAuthenticated} />
            <div
              className="table-wrap"
              style={{ zIndex: isAuthenticated ? "2" : "auto" }}
            >
              <div
                className="table-body"
                style={{
                  width: `${currentTableWidthWithMax}px`,
                  height: "500px",
                }}
              >
                <VariableSizeGrid
                  columnCount={reportMapping.length}
                  columnWidth={(index) =>
                    index + 1 === reportMapping.length
                      ? columnWidth + headerRowScrollbarOffset
                      : columnWidth
                  }
                  height={60}
                  ref={headerRow}
                  rowCount={1}
                  rowHeight={() => 60}
                  style={{
                    overflowX: "hidden",
                    overflowY: "hidden",
                  }}
                  width={currentTableWidthWithMax}
                >
                  {HeaderCell}
                </VariableSizeGrid>
                <FixedSizeGrid
                  columnCount={reportMapping.length}
                  className="table-grid"
                  id="table-grid"
                  rowCount={
                    filteredAndSortedTableData.length
                      ? filteredAndSortedTableData.length
                      : 0
                  }
                  columnWidth={columnWidth}
                  rowHeight={30}
                  height={500}
                  // overscanRowCount={5}
                  width={currentTableWidthWithMax}
                  onScroll={({ scrollLeft }) => {
                    headerRow.current.scrollTo({ scrollLeft });
                  }}
                >
                  {Cell}
                </FixedSizeGrid>
              </div>
              {isTablePageLoading && (
                <div className="table-overlay">Loading Page</div>
              )}
            </div>
            {filteredAndSortedTableData.length === totalItems ? (
              <div className="report-table-total">
                Total items: {totalItems}
              </div>
            ) : (
              <div className="report-table-total">
                Total items:{" "}
                {`${totalItems} (Showing ${filteredAndSortedTableData.length} items)`}
              </div>
            )}
            {currentReportCount > 9500000 && (
              <div className="report-txt-warning">
                <div className="first-line">
                  &quot;Export Now as TXT&quot; button disabled
                </div>
                <div className="second-line">
                  Please note that Genie can export files immediately with a
                  maximum of 9.5 million rows. If you wish to export more data,
                  please schedule the Export job by either two of the buttons on
                  the left.
                </div>
              </div>
            )}
          </animated.div>
        ) : null}

        {filteredAndSortedTableData &&
          currentTableData.length === 0 &&
          !filteredAndSortedTableData.length && (
            <animated.div
              className="report-table-bottom"
              style={{ zIndex: isAuthenticated ? "5" : "auto" }}
            >
              <div className="zero-results-text-1">
                Unable to return results
              </div>
              {hasTableLoadingFailed ? (
                <div className="zero-results-text-2">
                  The report you are trying to retrieve has failed due to a
                  timeout. Please check your internet connection and try again.
                </div>
              ) : (
                <div className="zero-results-text-2">
                  The filters you have selected are too specific and no data
                  exits for those parameters. Please try again by removing some
                  of the filter parameters to retrieve your data.
                </div>
              )}
            </animated.div>
          )}
      </>
    );
  }
};

export default memo(ReportTable);
