import React, { memo } from "react";
import Pagination from "@mui/material/Pagination";
import { useSelector } from "react-redux";
import { selectReportState } from "stateMgmt/reportSlice";
import Stack from "@mui/material/Stack";

// import {
//   initiateCEEScoreDetailPageLoad,
//   initiateCEEScorePageLoad,
//   initiateDRTPageLoad,
//   initiateOUPageLoad,
//   initiatePSNUPageLoad,
//   initiateSIMSPageLoad
// } from 'actions/sessionActions'
// import {
//   CEE_SCORE,
//   CEE_SCORE_DETAIL,
//   DATA_REVIEW_TOOL_EXTRACT,
//   OU_BY_IM,
//   PSNU_BY_IM,
//   SITE_BY_IM_EXTRACT
// } from 'constants/ReportTypes'

const PaginationBar = ({ isAuthenticated }) => {
  // const dispatch = useDispatch()
  const currentReportState = useSelector(selectReportState);
  const reportCount = currentReportState.currentReportCount;
  const tableIncrement = currentReportState.currentTableIncrement;
  const currentPageNum = currentReportState.currentTablePage;
  const currentReportName = currentReportState.currentReportName;
  const totalNumOfPages = Math.ceil(reportCount / tableIncrement);

  const handlePageChange = (e, page) => {
    switch (currentReportName) {
      // case SITE_BY_IM_EXTRACT:
      //     dispatch(initiateSIMSPageLoad(page))
      //     break
      // case PSNU_BY_IM:
      //     dispatch(initiatePSNUPageLoad(page))
      //     break
      // case OU_BY_IM:
      //     dispatch(initiateOUPageLoad(page))
      //     break
      // case CEE_SCORE:
      //     dispatch(initiateCEEScorePageLoad(page))
      //     break
      // case CEE_SCORE_DETAIL:
      //     dispatch(initiateCEEScoreDetailPageLoad(page))
      //     break
      // case DATA_REVIEW_TOOL_EXTRACT:
      //     dispatch(initiateDRTPageLoad(page))
      //     break
      default:
        break;
    }
  };

  return (
    <Stack
      sx={{
        marginBottom: "25px",
        zIndex: isAuthenticated ? "2" : "auto",
      }}
    >
      <Pagination
        boundaryCount={2}
        count={totalNumOfPages}
        onChange={handlePageChange}
        page={currentPageNum}
        siblingCount={2}
        shape="rounded"
        showFirstButton
        showLastButton
        sx={{
          zIndex: isAuthenticated ? "2" : "auto",
        }}
        variant="outlined"
      />
    </Stack>
  );
};

export default memo(PaginationBar);
