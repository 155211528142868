export const SiteByIMTable = [
  {field: 'orgunituid', displayName: 'org unit uid', width: 150, enableColumnResize: true, enablehiding: false},
  {field: 'sitename', displayName: 'sitename', width: 150, enableColumnResize: true},
  {field: 'operatingunit', displayName: 'operating unit', width: 150, enableColumnResize: true, enablehiding: false},
  {field: 'operatingunituid', displayName: 'operating unit uid', width: 150, enableColumnResize: true, enablehiding: false},
  {field: 'country', displayName: 'country', width: 150, enableColumnResize: true},
  {field: 'snu1', displayName: 'snu1', width: 150, enableColumnResize: true},
  {field: 'snu1uid', displayName: 'snu1uid', width: 150, enableColumnResize: true},
  {field: 'snu2', displayName: 'snu2', width: 150, enableColumnResize: true},
  {field: 'snu2uid', displayName: 'snu2uid', width: 150, enableColumnResize: true},
  {field: 'cop22_psnu', displayName: 'cop22_psnu', width: 150, enableColumnResize: true},
  {field: 'cop22_psnuuid', displayName: 'cop22_psnuuid', width: 150, enableColumnResize: true},
  {field: 'cop22_snuprioritization', displayName: 'cop22_snuprioritization', width: 150, enableColumnResize: true},
  {field: 'psnu', displayName: 'psnu', width: 150, enableColumnResize: true, enablehiding: false},
  {field: 'psnuuid', displayName: 'psnu uid', width: 150, enableColumnResize: true},
  {field: 'snuprioritization', displayName: 'snu prioritization', width: 150, enableColumnResize: true},
  {field: 'typemilitary', displayName: 'typemilitary', width: 150, enableColumnResize: true},
  {field: 'dreams', displayName: 'dreams', width: 150, enableColumnResize: true},
  {field: 'prime_partner_name', displayName: 'prime_partner_name', width: 150, enableColumnResize: true},
  {field: 'funding_agency', displayName: 'funding_agency', width: 150, enableColumnResize: true},
  {field: 'mech_code', displayName: 'mech_code', width: 150, enableColumnResize: true},
  {field: 'mech_name', displayName: 'mech_name', width: 150, enableColumnResize: true},
  {field: 'prime_partner_duns', displayName: 'prime_partner_duns', width: 150, enableColumnResize: true},
  {field: 'prime_partner_uei', displayName: 'prime_partner_uei', width: 150, enableColumnResize: true},
  {field: 'is_indigenous_prime_partner', displayName: 'is_indigenous_prime_partner', width: 150, enableColumnResize: true},
  {field: 'award_number', displayName: 'award_number', width: 150, enableColumnResize: true},
  {field: 'communityuid', displayName: 'community uid', width: 150, enableColumnResize: true},
  {field: 'community', displayName: 'community', width: 150, enableColumnResize: true},
  {field: 'facilityuid', displayName: 'facility uid', width: 150, enableColumnResize: true},
  {field: 'facility', displayName: 'facility', width: 150, enableColumnResize: true},
  {field: 'sitetype', displayName: 'site type', width: 150, enableColumnResize: true},
  {field: 'dataelementuid', displayName: 'data element uid', width: 150, enableColumnResize: true},
  {field: 'indicator', displayName: 'indicator', width: 150, enableColumnResize: true, enablehiding: false},
  {field: 'numeratordenom', displayName: 'numerator denominator', width: 150, enableColumnResize: true, enablehiding: false},
  {field: 'indicatortype', displayName: 'indicator type', width: 150, enableColumnResize: true},
  {field: 'standardizeddisaggregate', displayName: 'standardized disaggregate', width: 150, enableColumnResize: true},
  {field: 'categoryoptioncombouid', displayName: 'category option combo uid', width: 150, enableColumnResize: true},
  {field: 'categoryoptioncomboname', displayName: 'category option combo name', width: 150, enableColumnResize: true, enablehiding: false},
  {field: 'use_for_age', displayName: 'use_for_age', width: 150, enableColumnResize: true},
  {field: 'ageasentered', displayName: 'ageasentered', width: 150, enableColumnResize: true},
  {field: 'age_2018', displayName: 'age_2018', width: 150, enableColumnResize: true},	
  {field: 'age_2019', displayName: 'age_2019', width: 150, enableColumnResize: true},	
  {field: 'target_age_2024', displayName: 'target_age_2024', width: 150, enableColumnResize: true},	
  {field: 'trendscoarse', displayName: 'trendscoarse', width: 150, enableColumnResize: true},
  {field: 'sex', displayName: 'sex', width: 150, enableColumnResize: true},
  {field: 'statushiv', displayName: 'status hiv', width: 150, enableColumnResize: true},
  {field: 'statustb', displayName: 'status tb', width: 150, enableColumnResize: true},	
  {field: 'statuscx', displayName: 'status cx', width: 150, enableColumnResize: true},
  {field: 'hiv_treatment_status', displayName: 'hiv_treatment_status', width: 150, enableColumnResize: true},
  {field: 'otherdisaggregate', displayName: 'other disaggregate', width: 150, enableColumnResize: true},
  {field: 'otherdisaggregate_sub', displayName: 'otherdisaggregate_sub', width: 150, enableColumnResize: true}, 
  {field: 'modality', displayName: 'modality', width: 150, enableColumnResize: true},
  {field: 'target_modality_2024', displayName: 'target_modality_2024', width: 150, enableColumnResize: true},
  {field: 'fiscal_year', displayName: 'fiscal_year', width: 150, enableColumnResize: true},
//         {field: 'agesmallestcompatible', width: 150, enableColumnResize: true},
  {field: 'targets', displayName: 'targets', width: 150, enableColumnResize: true},
  {field: 'qtr1', displayName: 'qtr1', width: 150, enableColumnResize: true},
  {field: 'qtr2', displayName: 'qtr2', width: 150, enableColumnResize: true},
  {field: 'qtr3', displayName: 'qtr3', width: 150, enableColumnResize: true},
  {field: 'qtr4', displayName: 'qtr4', width: 150, enableColumnResize: true},
  {field: 'tieredsitecounts', displayName: 'tiered site counts', width: 150, visible: false, enableColumnResize: true},
  {field: 'typetieredsupport', displayName: 'type tiered support', width: 150, visible: false, enableColumnResize: true},
  {field: 'cumulative', displayName: 'cumulative', width: 150, enableColumnResize: true},
  {field: 'safe_for_net_new', displayName: 'safe_for_net_new', width: 150, enableColumnResize: true},
  {field: 'safe_for_vlc', displayName: 'safe_for_vlc', width: 150, enableColumnResize: true},
  {field: 'source_name', displayName: 'source_name', width: 150, enableColumnResize: true},
  {field: 'approvallevel', displayName: 'approvallevel', width: 150, enableColumnResize: true},
  {field: 'approvalleveldescription', displayName: 'approvalleveldescription', width: 150, enableColumnResize: true}
];

export const SiteByIMTableFrozen = [
  {field: 'orgunituid', displayName: 'org unit uid', width: 150, enableColumnResize: true, enablehiding: false},
  {field: 'sitename', displayName: 'sitename', width: 150, enableColumnResize: true},
  {field: 'operatingunit', displayName: 'operating unit', width: 150, enableColumnResize: true, enablehiding: false},
  {field: 'operatingunituid', displayName: 'operating unit uid', width: 150, enableColumnResize: true, enablehiding: false},
  {field: 'country', displayName: 'country', width: 150, enableColumnResize: true},
  {field: 'snu1', displayName: 'snu1', width: 150, enableColumnResize: true},
  {field: 'snu1uid', displayName: 'snu1uid', width: 150, enableColumnResize: true},
  {field: 'snu2', displayName: 'snu2', width: 150, enableColumnResize: true},
  {field: 'snu2uid', displayName: 'snu2uid', width: 150, enableColumnResize: true},
  {field: 'cop22_psnu', displayName: 'cop22_psnu', width: 150, enableColumnResize: true},
  {field: 'cop22_psnuuid', displayName: 'cop22_psnuuid', width: 150, enableColumnResize: true},
  {field: 'cop22_snuprioritization', displayName: 'cop22_snuprioritization', width: 150, enableColumnResize: true},
  {field: 'psnu', displayName: 'psnu', width: 150, enableColumnResize: true, enablehiding: false},
  {field: 'psnuuid', displayName: 'psnu uid', width: 150, enableColumnResize: true},
  {field: 'snuprioritization', displayName: 'snu prioritization', width: 150, enableColumnResize: true},
  {field: 'typemilitary', displayName: 'typemilitary', width: 150, enableColumnResize: true},
  {field: 'dreams', displayName: 'dreams', width: 150, enableColumnResize: true},
  {field: 'prime_partner_name', displayName: 'prime_partner_name', width: 150, enableColumnResize: true},
  {field: 'funding_agency', displayName: 'funding_agency', width: 150, enableColumnResize: true},
  {field: 'mech_code', displayName: 'mech_code', width: 150, enableColumnResize: true},
  {field: 'mech_name', displayName: 'mech_name', width: 150, enableColumnResize: true},
  {field: 'prime_partner_duns', displayName: 'prime_partner_duns', width: 150, enableColumnResize: true},
  {field: 'prime_partner_uei', displayName: 'prime_partner_uei', width: 150, enableColumnResize: true},
  {field: 'is_indigenous_prime_partner', displayName: 'is_indigenous_prime_partner', width: 150, enableColumnResize: true},
  {field: 'award_number', displayName: 'award_number', width: 150, enableColumnResize: true},
  {field: 'communityuid', displayName: 'community uid', width: 150, enableColumnResize: true},
  {field: 'community', displayName: 'community', width: 150, enableColumnResize: true},
  {field: 'facilityuid', displayName: 'facility uid', width: 150, enableColumnResize: true},
  {field: 'facility', displayName: 'facility', width: 150, enableColumnResize: true},
  {field: 'sitetype', displayName: 'site type', width: 150, enableColumnResize: true},
  {field: 'dataelementuid', displayName: 'data element uid', width: 150, enableColumnResize: true},
  {field: 'indicator', displayName: 'indicator', width: 150, enableColumnResize: true, enablehiding: false},
  {field: 'numeratordenom', displayName: 'numerator denominator', width: 150, enableColumnResize: true, enablehiding: false},
  {field: 'indicatortype', displayName: 'indicator type', width: 150, enableColumnResize: true},
  {field: 'standardizeddisaggregate', displayName: 'standardized disaggregate', width: 150, enableColumnResize: true},
  {field: 'categoryoptioncombouid', displayName: 'category option combo uid', width: 150, enableColumnResize: true},
  {field: 'categoryoptioncomboname', displayName: 'category option combo name', width: 150, enableColumnResize: true, enablehiding: false},
  {field: 'use_for_age', displayName: 'use_for_age', width: 150, enableColumnResize: true},
  {field: 'ageasentered', displayName: 'ageasentered', width: 150, enableColumnResize: true},
  {field: 'age_2018', displayName: 'age_2018', width: 150, enableColumnResize: true},
  {field: 'age_2019', displayName: 'age_2019', width: 150, enableColumnResize: true},
  {field: 'target_age_2024', displayName: 'target_age_2024', width: 150, enableColumnResize: true},	
  {field: 'trendscoarse', displayName: 'trendscoarse', width: 150, enableColumnResize: true},
  {field: 'sex', displayName: 'sex', width: 150, enableColumnResize: true},
  {field: 'statushiv', displayName: 'status hiv', width: 150, enableColumnResize: true},
  {field: 'statustb', displayName: 'status tb', width: 150, enableColumnResize: true},	
  {field: 'statuscx', displayName: 'status cx', width: 150, enableColumnResize: true},
  {field: 'hiv_treatment_status', displayName: 'hiv_treatment_status', width: 150, enableColumnResize: true},
  {field: 'otherdisaggregate', displayName: 'other disaggregate', width: 150, enableColumnResize: true},
  {field: 'otherdisaggregate_sub', displayName: 'otherdisaggregate_sub', width: 150, enableColumnResize: true}, 
  {field: 'modality', displayName: 'modality', width: 150, enableColumnResize: true},
  {field: 'target_modality_2024', displayName: 'target_modality_2024', width: 150, enableColumnResize: true},
  {field: 'fiscal_year', displayName: 'fiscal_year', width: 150, enableColumnResize: true},
//         {field: 'agesmallestcompatible', width: 150, enableColumnResize: true},
  {field: 'targets', displayName: 'targets', width: 150, enableColumnResize: true},
  {field: 'qtr1', displayName: 'qtr1', width: 150, enableColumnResize: true},
  {field: 'qtr2', displayName: 'qtr2', width: 150, enableColumnResize: true},
  {field: 'qtr3', displayName: 'qtr3', width: 150, enableColumnResize: true},
  {field: 'qtr4', displayName: 'qtr4', width: 150, enableColumnResize: true},
  {field: 'tieredsitecounts', displayName: 'tiered site counts', width: 150, visible: false, enableColumnResize: true},
  {field: 'typetieredsupport', displayName: 'type tiered support', width: 150, visible: false, enableColumnResize: true},
  {field: 'cumulative', displayName: 'cumulative', width: 150, enableColumnResize: true},
  {field: 'safe_for_net_new', displayName: 'safe_for_net_new', width: 150, enableColumnResize: true},
  {field: 'safe_for_vlc', displayName: 'safe_for_vlc', width: 150, enableColumnResize: true},
  {field: 'source_name', displayName: 'source_name', width: 150, enableColumnResize: true},
  {field: 'approvallevel', displayName: 'approvallevel', width: 150, enableColumnResize: true},
  {field: 'approvalleveldescription', displayName: 'approvalleveldescription', width: 150, enableColumnResize: true}
];

export const SiteByIMTableProcessed = SiteByIMTable.filter((entry) => entry.visible !== false);
export const SiteByIMTableFrozenProcessed = SiteByIMTableFrozen.filter((entry) => entry.visible !== false);

export const PSNUByIMTable = [
  {field: 'operatingunit', displayName: 'operating unit', width: 150, enableColumnResize: true},
  {field: 'operatingunituid', displayName: 'operating unit uid', width: 150, enableColumnResize: true},
  {field: 'country', displayName: 'country', width: 150, enableColumnResize: true},
  {field: 'snu1', displayName: 'snu1', width: 150, enableColumnResize: true},	
  {field: 'snu1uid', displayName: 'snu1uid', width: 150, enableColumnResize: true},	
  {field: 'cop22_psnu', displayName: 'cop22_psnu', width: 150, enableColumnResize: true},	
  {field: 'cop22_psnuuid', displayName: 'cop22_psnuuid', width: 150, enableColumnResize: true},	
  {field: 'cop22_snuprioritization', displayName: 'cop22_snuprioritization', width: 150, enableColumnResize: true},	
  {field: 'psnu', displayName: 'psnu', width: 150, enableColumnResize: true},	
  {field: 'psnuuid', displayName: 'psnuuid', width: 150, enableColumnResize: true},	
  {field: 'snuprioritization', displayName: 'snuprioritization', width: 150, enableColumnResize: true},	
  {field: 'typemilitary', displayName: 'typemilitary', width: 150, enableColumnResize: true},
  {field: 'dreams', displayName: 'dreams', width: 150, enableColumnResize: true},
  {field: 'prime_partner_name', displayName: 'prime_partner_name', width: 150, enableColumnResize: true}, 
  {field: 'funding_agency', displayName: 'funding_agency', width: 150, enableColumnResize: true},
  {field: 'mech_code', displayName: 'mech_code', width: 150, enableColumnResize: true},
  {field: 'mech_name', displayName: 'mech_name', width: 150, enableColumnResize: true},
  {field: 'prime_partner_duns', displayName: 'prime_partner_duns', width: 150, enableColumnResize: true},
  {field: 'prime_partner_uei', displayName: 'prime_partner_uei', width: 150, enableColumnResize: true},
  {field: 'is_indigenous_prime_partner', displayName: 'is_indigenous_prime_partner', width: 150, enableColumnResize: true},
  {field: 'award_number', displayName: 'award_number', width: 150, enableColumnResize: true},
  {field: 'dataelementuid', displayName: 'data element uid', width: 150, enableColumnResize: true},
  {field: 'indicator', displayName: 'indicator', width: 150, enableColumnResize: true},
  {field: 'numeratordenom', displayName: 'numerator denom', width: 150, enableColumnResize: true},
  {field: 'indicatortype', displayName: 'indicator type', width: 150, enableColumnResize: true},
  {field: 'standardizeddisaggregate', displayName: 'standardized disaggregate', width: 150, enableColumnResize: true},
  {field: 'categoryoptioncombouid', displayName: 'category option combo uid', width: 150, enableColumnResize: true},
  {field: 'categoryoptioncomboname', displayName: 'category option combo name', width: 150, enableColumnResize: true},
  {field: 'use_for_age', displayName: 'use_for_age', width: 150, enableColumnResize: true},
  {field: 'ageasentered', displayName: 'ageasentered', width: 150, enableColumnResize: true},
  {field: 'age_2018', displayName: 'age_2018', width: 150, enableColumnResize: true},	
  {field: 'age_2019', displayName: 'age_2019', width: 150, enableColumnResize: true},	
  {field: 'target_age_2024', displayName: 'target_age_2024', width: 150, enableColumnResize: true},	
  {field: 'trendscoarse', displayName: 'trendscoarse', width: 150, enableColumnResize: true},
  {field: 'sex', displayName: 'sex', width: 150, enableColumnResize: true},
  {field: 'statushiv', displayName: 'status hiv', width: 150, enableColumnResize: true},
  {field: 'statustb', displayName: 'status tb', width: 150, enableColumnResize: true},	
  {field: 'statuscx', displayName: 'status cx', width: 150, enableColumnResize: true},
  {field: 'hiv_treatment_status', displayName: 'hiv_treatment_status', width: 150, enableColumnResize: true},
  {field: 'otherdisaggregate', displayName: 'other disaggregate', width: 150, enableColumnResize: true},
  {field: 'otherdisaggregate_sub', displayName: 'otherdisaggregate_sub', width: 150, enableColumnResize: true}, 
  {field: 'modality', displayName: 'modality', width: 150, enableColumnResize: true},
  {field: 'target_modality_2024', displayName: 'target_modality_2024', width: 150, enableColumnResize: true},
  {field: 'fiscal_year', displayName: 'fiscal_year', width: 150, enableColumnResize: true},
//         {field: 'agesmallestcompatible', width: 150, enableColumnResize: true},
  {field: 'targets', displayName: 'targets', width: 150, enableColumnResize: true},
  {field: 'qtr1', displayName: 'qtr1', width: 150, enableColumnResize: true},
  {field: 'qtr2', displayName: 'qtr2', width: 150, enableColumnResize: true},
  {field: 'qtr3', displayName: 'qtr3', width: 150, enableColumnResize: true},
  {field: 'qtr4', displayName: 'qtr4', width: 150, enableColumnResize: true},
  {field: 'cumulative', displayName: 'cumulative', width: 150, enableColumnResize: true},
  {field: 'safe_for_net_new', displayName: 'safe_for_net_new', width: 150, enableColumnResize: true},
  {field: 'safe_for_vlc', displayName: 'safe_for_vlc', width: 150, enableColumnResize: true},
  {field: 'source_name', displayName: 'source_name', width: 150, enableColumnResize: true},
  {field: 'approvallevel', displayName: 'approvallevel', width: 150, enableColumnResize: true},
  {field: 'approvalleveldescription', displayName: 'approvalleveldescription', width: 150, enableColumnResize: true}
];

export const PSNUByIMTableFrozen = [
  {field: 'operatingunit', displayName: 'operating unit', width: 150, enableColumnResize: true},
  {field: 'operatingunituid', displayName: 'operating unit uid', width: 150, enableColumnResize: true},
  {field: 'country', displayName: 'country', width: 150, enableColumnResize: true},
  {field: 'snu1', displayName: 'snu1', width: 150, enableColumnResize: true},	
  {field: 'snu1uid', displayName: 'snu1uid', width: 150, enableColumnResize: true},	
  {field: 'cop22_psnu', displayName: 'cop22_psnu', width: 150, enableColumnResize: true},	
  {field: 'cop22_psnuuid', displayName: 'cop22_psnuuid', width: 150, enableColumnResize: true},
  {field: 'cop22_snuprioritization', displayName: 'cop22_snuprioritization', width: 150, enableColumnResize: true},		
  {field: 'psnu', displayName: 'psnu', width: 150, enableColumnResize: true},	
  {field: 'psnuuid', displayName: 'psnuuid', width: 150, enableColumnResize: true},	
  {field: 'snuprioritization', displayName: 'snuprioritization', width: 150, enableColumnResize: true},	
  {field: 'typemilitary', displayName: 'typemilitary', width: 150, enableColumnResize: true},
  {field: 'dreams', displayName: 'dreams', width: 150, enableColumnResize: true},
  {field: 'prime_partner_name', displayName: 'prime_partner_name', width: 150, enableColumnResize: true}, 
  {field: 'funding_agency', displayName: 'funding_agency', width: 150, enableColumnResize: true},
  {field: 'mech_code', displayName: 'mech_code', width: 150, enableColumnResize: true},
  {field: 'mech_name', displayName: 'mech_name', width: 150, enableColumnResize: true},
  {field: 'prime_partner_duns', displayName: 'prime_partner_duns', width: 150, enableColumnResize: true},
  {field: 'prime_partner_uei', displayName: 'prime_partner_uei', width: 150, enableColumnResize: true},
  {field: 'is_indigenous_prime_partner', displayName: 'is_indigenous_prime_partner', width: 150, enableColumnResize: true},
  {field: 'award_number', displayName: 'award_number', width: 150, enableColumnResize: true},
  {field: 'dataelementuid', displayName: 'data element uid', width: 150, enableColumnResize: true},
  {field: 'indicator', displayName: 'indicator', width: 150, enableColumnResize: true},
  {field: 'numeratordenom', displayName: 'numerator denom', width: 150, enableColumnResize: true},
  {field: 'indicatortype', displayName: 'indicator type', width: 150, enableColumnResize: true},
  {field: 'standardizeddisaggregate', displayName: 'standardized disaggregate', width: 150, enableColumnResize: true},
  {field: 'categoryoptioncombouid', displayName: 'category option combo uid', width: 150, enableColumnResize: true},
  {field: 'categoryoptioncomboname', displayName: 'category option combo name', width: 150, enableColumnResize: true},
  {field: 'use_for_age', displayName: 'use_for_age', width: 150, enableColumnResize: true},
  {field: 'ageasentered', displayName: 'ageasentered', width: 150, enableColumnResize: true},
  {field: 'age_2018', displayName: 'age_2018', width: 150, enableColumnResize: true},
  {field: 'age_2019', displayName: 'age_2019', width: 150, enableColumnResize: true},
  {field: 'target_age_2024', displayName: 'target_age_2024', width: 150, enableColumnResize: true},	
  {field: 'trendscoarse', displayName: 'trendscoarse', width: 150, enableColumnResize: true},
  {field: 'sex', displayName: 'sex', width: 150, enableColumnResize: true},
  {field: 'statushiv', displayName: 'status hiv', width: 150, enableColumnResize: true},
  {field: 'statustb', displayName: 'status tb', width: 150, enableColumnResize: true},	
  {field: 'statuscx', displayName: 'status cx', width: 150, enableColumnResize: true},
  {field: 'hiv_treatment_status', displayName: 'hiv_treatment_status', width: 150, enableColumnResize: true},
  {field: 'otherdisaggregate', displayName: 'other disaggregate', width: 150, enableColumnResize: true},
  {field: 'otherdisaggregate_sub', displayName: 'otherdisaggregate_sub', width: 150, enableColumnResize: true}, 
  {field: 'modality', displayName: 'modality', width: 150, enableColumnResize: true},
  {field: 'target_modality_2024', displayName: 'target_modality_2024', width: 150, enableColumnResize: true},
  {field: 'fiscal_year', displayName: 'fiscal_year', width: 150, enableColumnResize: true},
  {field: 'targets', displayName: 'targets', width: 150, enableColumnResize: true},
  {field: 'qtr1', displayName: 'qtr1', width: 150, enableColumnResize: true},
  {field: 'qtr2', displayName: 'qtr2', width: 150, enableColumnResize: true},
  {field: 'qtr3', displayName: 'qtr3', width: 150, enableColumnResize: true},
  {field: 'qtr4', displayName: 'qtr4', width: 150, enableColumnResize: true},
  {field: 'cumulative', displayName: 'cumulative', width: 150, enableColumnResize: true},
  {field: 'safe_for_net_new', displayName: 'safe_for_net_new', width: 150, enableColumnResize: true},
  {field: 'safe_for_vlc', displayName: 'safe_for_vlc', width: 150, enableColumnResize: true},
  {field: 'source_name', displayName: 'source_name', width: 150, enableColumnResize: true},
  {field: 'approvallevel', displayName: 'approvallevel', width: 150, enableColumnResize: true},
  {field: 'approvalleveldescription', displayName: 'approvalleveldescription', width: 150, enableColumnResize: true}
];

export const OUByIMTable = [
  {field: 'operatingunit', displayName: 'operating unit', width: 150, enableColumnResize: true},
  {field: 'operatingunituid', displayName: 'operating unit uid', width: 150, enableColumnResize: true},
  {field: 'country', displayName: 'country', width: 150, enableColumnResize: true},
  {field: 'typemilitary', displayName: 'typemilitary', width: 150, enableColumnResize: true},
  {field: 'prime_partner_name', displayName: 'prime_partner_name', width: 150, enableColumnResize: true}, 
  {field: 'funding_agency', displayName: 'funding_agency', width: 150, enableColumnResize: true},
  {field: 'mech_code', displayName: 'mech_code', width: 150, enableColumnResize: true},
  {field: 'mech_name', displayName: 'mech_name', width: 150, enableColumnResize: true},
  {field: 'prime_partner_duns', displayName: 'prime_partner_duns', width: 150, enableColumnResize: true},
  {field: 'prime_partner_uei', displayName: 'prime_partner_uei', width: 150, enableColumnResize: true},
  {field: 'is_indigenous_prime_partner', displayName: 'is_indigenous_prime_partner', width: 150, enableColumnResize: true},
  {field: 'award_number', displayName: 'award_number', width: 150, enableColumnResize: true},
  {field: 'dataelementuid', displayName: 'data element uid', width: 150, enableColumnResize: true},
  {field: 'indicator', displayName: 'indicator', width: 150, enableColumnResize: true},
  {field: 'numeratordenom', displayName: 'numerator denom', width: 150, enableColumnResize: true},
  {field: 'indicatortype', displayName: 'indicator type', width: 150, enableColumnResize: true},
  {field: 'standardizeddisaggregate', displayName: 'standardized disaggregate', width: 150, enableColumnResize: true},
  {field: 'categoryoptioncombouid', displayName: 'category option combo uid', width: 150, enableColumnResize: true},
  {field: 'categoryoptioncomboname', displayName: 'category option combo name', width: 150, enableColumnResize: true},
  {field: 'use_for_age', displayName: 'use_for_age', width: 150, enableColumnResize: true},
  {field: 'ageasentered', displayName: 'ageasentered', width: 150, enableColumnResize: true},
  {field: 'age_2018', displayName: 'age_2018', width: 150, enableColumnResize: true},
  {field: 'age_2019', displayName: 'age_2019', width: 150, enableColumnResize: true},
  {field: 'target_age_2024', displayName: 'target_age_2024', width: 150, enableColumnResize: true},
  {field: 'trendscoarse', displayName: 'trendscoarse', width: 150, enableColumnResize: true},
  {field: 'sex', displayName: 'sex', width: 150, enableColumnResize: true},
  {field: 'statushiv', displayName: 'status hiv', width: 150, enableColumnResize: true},
  {field: 'statustb', displayName: 'status tb', width: 150, enableColumnResize: true},	
  {field: 'statuscx', displayName: 'status cx', width: 150, enableColumnResize: true},
  {field: 'hiv_treatment_status', displayName: 'hiv_treatment_status', width: 150, enableColumnResize: true},
  {field: 'otherdisaggregate', displayName: 'other disaggregate', width: 150, enableColumnResize: true},
  {field: 'otherdisaggregate_sub', displayName: 'otherdisaggregate_sub', width: 150, enableColumnResize: true}, 
  {field: 'modality', displayName: 'modality', width: 150, enableColumnResize: true},
  {field: 'target_modality_2024', displayName: 'target_modality_2024', width: 150, enableColumnResize: true},
  {field: 'fiscal_year', displayName: 'fiscal_year', width: 150, enableColumnResize: true},
  {field: 'targets', displayName: 'targets', width: 150, enableColumnResize: true},
  {field: 'qtr1', displayName: 'qtr1', width: 150, enableColumnResize: true},
  {field: 'qtr2', displayName: 'qtr2', width: 150, enableColumnResize: true},
  {field: 'qtr3', displayName: 'qtr3', width: 150, enableColumnResize: true},
  {field: 'qtr4', displayName: 'qtr4', width: 150, enableColumnResize: true},
  {field: 'cumulative', displayName: 'cumulative', width: 150, enableColumnResize: true},
  {field: 'safe_for_net_new', displayName: 'safe_for_net_new', width: 150, enableColumnResize: true},
  {field: 'safe_for_vlc', displayName: 'safe_for_vlc', width: 150, enableColumnResize: true},
  {field: 'source_name', displayName: 'source_name', width: 150, enableColumnResize: true},
  {field: 'approvallevel', displayName: 'approvallevel', width: 150, enableColumnResize: true},
  {field: 'approvalleveldescription', displayName: 'approvalleveldescription', width: 150, enableColumnResize: true}
];

export const OUByIMTableFrozen = [
  {field: 'operatingunit', displayName: 'operating unit', width: 150, enableColumnResize: true},
  {field: 'operatingunituid', displayName: 'operating unit uid', width: 150, enableColumnResize: true},
  {field: 'country', displayName: 'country', width: 150, enableColumnResize: true},
  {field: 'typemilitary', displayName: 'typemilitary', width: 150, enableColumnResize: true},
  {field: 'prime_partner_name', displayName: 'prime_partner_name', width: 150, enableColumnResize: true}, 
  {field: 'funding_agency', displayName: 'funding_agency', width: 150, enableColumnResize: true},
  {field: 'mech_code', displayName: 'mech_code', width: 150, enableColumnResize: true},
  {field: 'mech_name', displayName: 'mech_name', width: 150, enableColumnResize: true},
  {field: 'prime_partner_duns', displayName: 'prime_partner_duns', width: 150, enableColumnResize: true},
  {field: 'prime_partner_uei', displayName: 'prime_partner_uei', width: 150, enableColumnResize: true},
  {field: 'is_indigenous_prime_partner', displayName: 'is_indigenous_prime_partner', width: 150, enableColumnResize: true},
  {field: 'award_number', displayName: 'award_number', width: 150, enableColumnResize: true},
  {field: 'dataelementuid', displayName: 'data element uid', width: 150, enableColumnResize: true},
  {field: 'indicator', displayName: 'indicator', width: 150, enableColumnResize: true},
  {field: 'numeratordenom', displayName: 'numerator denom', width: 150, enableColumnResize: true},
  {field: 'indicatortype', displayName: 'indicator type', width: 150, enableColumnResize: true},
  {field: 'standardizeddisaggregate', displayName: 'standardized disaggregate', width: 150, enableColumnResize: true},
  {field: 'categoryoptioncombouid', displayName: 'category option combo uid', width: 150, enableColumnResize: true},
  {field: 'categoryoptioncomboname', displayName: 'category option combo name', width: 150, enableColumnResize: true},
  {field: 'use_for_age', displayName: 'use_for_age', width: 150, enableColumnResize: true},
  {field: 'ageasentered', displayName: 'ageasentered', width: 150, enableColumnResize: true},
  {field: 'age_2018', displayName: 'age_2018', width: 150, enableColumnResize: true},
  {field: 'age_2019', displayName: 'age_2019', width: 150, enableColumnResize: true},
  {field: 'target_age_2024', displayName: 'target_age_2024', width: 150, enableColumnResize: true},
  {field: 'trendscoarse', displayName: 'trendscoarse', width: 150, enableColumnResize: true},
  {field: 'sex', displayName: 'sex', width: 150, enableColumnResize: true},
  {field: 'statushiv', displayName: 'status hiv', width: 150, enableColumnResize: true},
  {field: 'statustb', displayName: 'status tb', width: 150, enableColumnResize: true},	
  {field: 'statuscx', displayName: 'status cx', width: 150, enableColumnResize: true},
  {field: 'hiv_treatment_status', displayName: 'hiv_treatment_status', width: 150, enableColumnResize: true},
  {field: 'otherdisaggregate', displayName: 'other disaggregate', width: 150, enableColumnResize: true},
  {field: 'otherdisaggregate_sub', displayName: 'otherdisaggregate_sub', width: 150, enableColumnResize: true}, 
  {field: 'modality', displayName: 'modality', width: 150, enableColumnResize: true},
  {field: 'target_modality_2024', displayName: 'target_modality_2024', width: 150, enableColumnResize: true},
  {field: 'fiscal_year', displayName: 'fiscal_year', width: 150, enableColumnResize: true},
  {field: 'targets', displayName: 'targets', width: 150, enableColumnResize: true},
  {field: 'qtr1', displayName: 'qtr1', width: 150, enableColumnResize: true},
  {field: 'qtr2', displayName: 'qtr2', width: 150, enableColumnResize: true},
  {field: 'qtr3', displayName: 'qtr3', width: 150, enableColumnResize: true},
  {field: 'qtr4', displayName: 'qtr4', width: 150, enableColumnResize: true},
  {field: 'cumulative', displayName: 'cumulative', width: 150, enableColumnResize: true},
  {field: 'safe_for_net_new', displayName: 'safe_for_net_new', width: 150, enableColumnResize: true},
  {field: 'safe_for_vlc', displayName: 'safe_for_vlc', width: 150, enableColumnResize: true},
  {field: 'source_name', displayName: 'source_name', width: 150, enableColumnResize: true},
  {field: 'approvallevel', displayName: 'approvallevel', width: 150, enableColumnResize: true},
  {field: 'approvalleveldescription', displayName: 'approvalleveldescription', width: 150, enableColumnResize: true}
];

export const FundingAgency = [
  {name: 'DOD', id: 'DOD'},
  {name: 'DOL', id: 'DOL'},
  {name: 'HHS/CDC', id: 'HHS/CDC'},
  {name: 'HHS/HRSA', id: 'HHS/HRSA'},
  {name: 'HHS/NIH', id: 'HHS/NIH'},
  {name: 'HHS/SAMHSA', id: 'HHS/SAMHSA'},
  {name: 'PC', id: 'PC'},
  {name: 'State/AF', id: 'State/AF'},
  {name: 'State/EAP', id: 'State/EAP'},
  {name: 'State/PRM', id: 'State/PRM'},
  {name: 'State/WHA', id: 'State/WHA'},
  {name: 'USAID', id: 'USAID'},
];

export const MyDownloads = [
  {field: 'FileName', displayName: 'File', cellTemplate:'<div class="text-center"><a ng-click="grid.appScope.download(\'{{row.entity.FileNameUrl}}\')" download><img style="cursor:pointer" ng-if="row.entity.Status == \'Completed\'" ng-src="images/download.png"/><img style="cursor:pointer" ng-if="row.entity.Status == \'Pending\'" ng-src="images/cancel.png"/><img class="text-center" ng-if="row.entity.Status == \'Cancelled\'" ng-src="images/cancelled.png"/><img class="text-center" ng-if="row.entity.Status == \'Processing\'" ng-src="images/processing.png"/></a></div>', width: 50, enableColumnResize: true, enablehiding: false},
  {field: 'Status', displayName: 'Status', width: 110, enableColumnResize: true},
  {field: 'ReportType', displayName: 'Report Type', width: 115, enableColumnResize: true},
  {field: 'RequestedTimeRelative', displayName: 'Requested Time', width: 140, enableColumnResize: true},
  {field: 'OperatingUnit', displayName: 'Operating Unit', width: 150, enableColumnResize: true},
  {field: 'FundingAgency', displayName: 'funding_agency', width: 150, enableColumnResize: true},
  {field: 'Indicator', displayName: 'Indicator', width: 150, enableColumnResize: true},
  {field: 'ExpirationDate', displayName: 'Valid Through', width: 140, enableColumnResize: true},
  {field: 'Parameters', displayName: 'Other Filters', width: 150, enableColumnResize: true},
  {field: 'ZipFormat', displayName: 'Zip Format', width: 110, enableColumnResize: true},
  {field: 'FileSizeInKb', displayName: 'File Size', width: 110, enableColumnResize: true},
  {field: 'DailyFrozen', displayName: 'Daily/Frozen', width: 100, enableColumnResize: true},
  {field: 'ActualHowLongInSeconds', displayName: 'How Long(Seconds)', width: 150, enableColumnResize: true},
  {field: 'JobId', displayName: 'ID', width: 65, enableColumnResize: true}
];

export const SIMSCEEScoreTable = [
  {field: 'fiscal_year', width: 150, enableColumnResize: true},
  {field: 'fiscal_quarter', width: 150, enableColumnResize: true},
  {field: 'operatingunit', displayName: 'Operating Unit', width: 150, enableColumnResize: true},
  {field: 'countryname', displayName: 'Country Name', width: 150, enableColumnResize: true},
  {field: 'site_type', displayName: 'Site Type', width: 150, enableColumnResize: true},
  {field: 'fundingagency', displayName: 'funding_agency', width: 150, enableColumnResize: true},
  {field: 'primepartner', displayName: 'Prime Partner', width: 150, enableColumnResize: true}, 
  {field: 'prime_partner_duns', displayName: 'prime_partner_duns', width: 150, enableColumnResize: true},
  {field: 'mech_code', displayName: 'mech_code', width: 150, enableColumnResize: true},
  {field: 'mech_name', displayName: 'mech_name', width: 150, enableColumnResize: true},
  {field: 'asmt_tool_type', width: 150, enableColumnResize: true},
  {field: 'asmt_first', width: 150, enableColumnResize: true},
  {field: 'asmt_last', width: 150, enableColumnResize: true},
  {field: 'asmt_type', width: 150, enableColumnResize: true},
  {field: 'asmt_version', width: 150, enableColumnResize: true},
  {field: 'asmt_prtd_performance', width: 150, enableColumnResize: true},
  {field: 'asmt_prtd_new_partner', width: 150, enableColumnResize: true},
  {field: 'asmt_prtd_new_site', width: 150, enableColumnResize: true},
  {field: 'asmt_prtd_scaling_activity', width: 150, enableColumnResize: true},
  {field: 'asmt_prtd_other_evidence_or_known_gap', width: 150, enableColumnResize: true},
  {field: 'asmt_prtd_other_text', width: 150, enableColumnResize: true},
  {field: 'asmt_prtd_set', width: 150, enableColumnResize: true},
  {field: 'cee_required', width: 150, enableColumnResize: true},
  {field: 'cee_id', width: 150, enableColumnResize: true},
  {field: 'cee_id_sims4', width: 150, enableColumnResize: true},
  {field: 'cee_id_sims3', width: 150, enableColumnResize: true},
  {field: 'cee_name', width: 150, enableColumnResize: true},
  {field: 'cee_standard', width: 150, enableColumnResize: true},
  {field: 'cee_comment', width: 150, enableColumnResize: true},
  {field: 'cee_number_of_asmt', width: 150, enableColumnResize: true},
  {field: 'cee_number_of_sites', width: 150, enableColumnResize: true},
  {field: 'cee_score_red', width: 150, enableColumnResize: true},
  {field: 'cee_score_yellow', width: 150, enableColumnResize: true},
  {field: 'cee_score_green', width: 150, enableColumnResize: true},
  {field: 'cee_score_na', width: 150, enableColumnResize: true},
];

export const SIMSCEEScoreDetailTable = [
  {field: 'period_srgt_key', width: 150, enableColumnResize: true},
  {field: 'fiscal_year', width: 150, enableColumnResize: true},
  {field: 'fiscal_quarter', width: 150, enableColumnResize: true},
  {field: 'operatingunit', displayName: 'Operating Unit', width: 150, enableColumnResize: true},
  {field: 'countryname', displayName: 'Country Name', width: 150, enableColumnResize: true},
  {field: 'fundingagency', displayName: 'funding_agency', width: 150, enableColumnResize: true},
  {field: 'asmt_tool_type', width: 150, enableColumnResize: true},
  {field: 'asmt_type', width: 150, enableColumnResize: true},
  {field: 'sims_set_id', width: 150, enableColumnResize: true},
  {field: 'cee_id', width: 150, enableColumnResize: true},
  {field: 'cee_name', width: 150, enableColumnResize: true},
  {field: 'cee_required', width: 150, enableColumnResize: true},
  {field: 'cee_standard', width: 150, enableColumnResize: true},
  {field: 'cee_score_red', width: 150, enableColumnResize: true},
  {field: 'cee_score_yellow', width: 150, enableColumnResize: true},
  {field: 'cee_score_green', width: 150, enableColumnResize: true},
  {field: 'cee_score_na', width: 150, enableColumnResize: true},
  {field: 'number_of_asmt', width: 150, enableColumnResize: true},
  {field: 'number_of_sites', width: 150, enableColumnResize: true},
];

export const DRTTable = [
  {field: 'operatingunit', displayName: 'Operating Unit', width: 150, enableColumnResize: true},
  {field: 'countryname', displayName: 'Country Name', width: 150, enableColumnResize: true},
  {field: 'snu1', displayName: 'SNU1', width: 150, enableColumnResize: true},
  {field: 'psnu', displayName: 'PSNU', width: 150, enableColumnResize: true},
  {field: 'fundingagency', displayName: 'funding_agency', width: 150, enableColumnResize: true},
  {field: 'check_level', displayName: 'Check Level', width: 150, enableColumnResize: true},
  {field: 'site_name', width: 150, enableColumnResize: true},
  {field: 'orgunituid', displayName: 'Org Unit ID', width: 150, enableColumnResize: true},
  {field: 'mech_code', displayName: 'Mechanism Code', width: 150, enableColumnResize: true},
  {field: 'mech_name', displayName: 'Mechanism Name', width: 150, enableColumnResize: true},
  {field: 'program_area', displayName: 'Program Area', width: 150, enableColumnResize: true},
  {field: 'flag_label', displayName: 'Flag Label', width: 150, enableColumnResize: true},
  {field: 'flag', width: 150, enableColumnResize: true},
  {field: 'ref1_indicator', width: 150, enableColumnResize: true},
  {field: 'ref1_value', width: 150, enableColumnResize: true},
  {field: 'ref2_indicator', width: 150, enableColumnResize: true},
  {field: 'ref2_value', width: 150, enableColumnResize: true},
  {field: 'flagtype_desc', displayName: 'Flag Type Description', width: 150, enableColumnResize: true},
  {field: 'approval_level', displayName: 'ApprovalLevel', width: 150, enableColumnResize: true},
  {field: 'approval_level_desc', displayName: 'ApprovalLevelDescription', width: 150, enableColumnResize: true}
];
