import axios from "axios";
import {
  LOAD_TOP_3_LEVELS,
  LOAD_ICPI,
  UPDATE_REPORT_TYPE,
  LOAD_FROZEN_ICPI,
  LOAD_LAST_SYNC,
  LOAD_LAST_SYNC_FROZEN,
  // UPDATE_ACTIVE_COUNTRIES,
  FETCH_REPORT,
  LOAD_CURRENT_TABLE,
  LOAD_TABLE_PAGE,
  INITIATE_INCREMENT_CHANGE,
  FETCH_ZIP_EXPORT,
  FETCH_SEVEN_ZIP_EXPORT,
  UPDATE_PARAMETER_COUNT,
  UPDATE_SORTED_COLUMN,
  UPDATE_SEARCH_LIST,
  UPDATE_SORTING_DIRECTION,
  REFRESH_DOWNLOADS,
  CLEAR_TABLE,
  CANCEL_FILE,
  UPDATE_TABLE_WIDTH,
  INITIATE_SIMS_PAGE_LOAD,
  INITIATE_SIMS_TABLE_LOAD,
  INITIATE_PSNU_TABLE_LOAD,
  INITIATE_OU_TABLE_LOAD,
  INITIATE_OU_PAGE_LOAD,
  INITIATE_PSNU_PAGE_LOAD,
  INITIATE_CEE_SCORE_PAGE_LOAD,
  INITIATE_CEE_SCORE_TABLE_LOAD,
  INITIATE_CEE_SCORE_DETAIL_PAGE_LOAD,
  INITIATE_CEE_SCORE_DETAIL_TABLE_LOAD,
  INITIATE_DRT_TABLE_LOAD,
  INITIATE_DRT_PAGE_LOAD,
  // UPDATE_EXPANDED,
  UPDATE_TEXT_EXPORT_LOADING,
  BASE_URL,
  LOAD_FILE,
} from "constants/Session";
import { saveAs } from "file-saver";

export const loadTop3Levels = () => ({
  types: [
    LOAD_TOP_3_LEVELS.PENDING,
    LOAD_TOP_3_LEVELS.SUCCESS,
    LOAD_TOP_3_LEVELS.FAILURE,
  ],
  payload: {
    client: "nightly",
    request: {
      url: "api/organisation_unit/organisation_unitTop3LevelsTree",
      method: "get",
    },
  },
});

export const loadICPI = () => ({
  types: [LOAD_ICPI.PENDING, LOAD_ICPI.SUCCESS, LOAD_ICPI.FAILURE],
  payload: {
    client: "nightly",
    request: {
      url: "api/Periods/ICPI",
      method: "get",
    },
  },
});

export const loadFrozenICPI = () => ({
  types: [
    LOAD_FROZEN_ICPI.PENDING,
    LOAD_FROZEN_ICPI.SUCCESS,
    LOAD_FROZEN_ICPI.FAILURE,
  ],
  payload: {
    client: "frozen",
    request: {
      url: "api/Periods/ICPI",
      method: "get",
    },
  },
});

export const loadLastSync = () => ({
  types: [
    LOAD_LAST_SYNC.PENDING,
    LOAD_LAST_SYNC.SUCCESS,
    LOAD_LAST_SYNC.FAILURE,
  ],
  payload: {
    client: "nightly",
    request: {
      url: "api/Configurations/Configuration2?key=LastSync",
      method: "get",
    },
  },
});

export const loadLastSyncFrozen = () => ({
  types: [
    LOAD_LAST_SYNC_FROZEN.PENDING,
    LOAD_LAST_SYNC_FROZEN.SUCCESS,
    LOAD_LAST_SYNC_FROZEN.FAILURE,
  ],
  payload: {
    client: "frozen",
    request: {
      url: "api/Configurations/Configuration2?key=LastSync",
      method: "get",
    },
  },
});

// export const updateExpanded = (expandedArray) => ({
//   type: UPDATE_EXPANDED,
//   expanded: expandedArray,
// });

export const updateReportType = (reportType) => ({
  type: UPDATE_REPORT_TYPE,
  payload: {
    reportType,
  },
});

// export const updateActiveCountries = (countryList) => ({
//   type: UPDATE_ACTIVE_COUNTRIES,
//   payload: {
//     countryList,
//   },
// });

export const loadPageData = (type, url, isFrozen) => {
  return (dispatch, getState) => {
    let existingCancelController = null;
    let tokenController = null;
    switch (type) {
      case "pageCount":
        existingCancelController =
          getState().sessionState.pageCountCancelController;
        if (existingCancelController)
          existingCancelController.cancel({ msg: "Page count cancelled" });
        tokenController = axios.CancelToken.source();
        // console.log("*** loadPageData.fetchRepot ***");
        // console.log(url);
        dispatch(fetchReport(url, isFrozen, tokenController));
        break;
      case "newTable":
        // Cancel page loading if exists
        const pageCancelController =
          getState().sessionState.newPageCancelController;
        if (pageCancelController)
          pageCancelController.cancel({ msg: "Page load cleared" });

        existingCancelController =
          getState().sessionState.tableCancelController;
        if (existingCancelController)
          existingCancelController.cancel({ msg: "Table load cancelled" });
        tokenController = axios.CancelToken.source();
        dispatch(loadCurrentTable(url, isFrozen, tokenController));
        break;
      case "newPage":
        existingCancelController =
          getState().sessionState.newPageCancelController;
        if (existingCancelController)
          existingCancelController.cancel({ msg: "Page load cancelled" });
        tokenController = axios.CancelToken.source();
        dispatch(loadCurrentPage(url, isFrozen, tokenController));
        break;
    }
  };
};

export const fetchPageCount = (url, isFrozen) => {
  return (dispatch, getState) => {
    const pageCountCancelController =
      getState().sessionState.pageCountCancelController;
    if (pageCountCancelController) {
      pageCountCancelController.cancel({ msg: "Page Count cancelled" });
    }
    const newController = axios.CancelToken.source();
    dispatch(fetchReport(url, isFrozen, newController));
  };
};
export const fetchReport = (url, isFrozen, controller) => ({
  types: [FETCH_REPORT.PENDING, FETCH_REPORT.SUCCESS, FETCH_REPORT.FAILURE],
  payload: {
    client: isFrozen ? "frozen" : "nightly",
    request: {
      url,
      method: "get",
      cancelToken: controller.token,
      timeout: 120000, // 2 min timeout
    },
  },
  controller,
});

export const loadCurrentTable = (url, isFrozen, controller) => ({
  types: [
    LOAD_CURRENT_TABLE.PENDING,
    LOAD_CURRENT_TABLE.SUCCESS,
    LOAD_CURRENT_TABLE.FAILURE,
  ],
  payload: {
    client: isFrozen ? "frozen" : "nightly",
    request: {
      url,
      method: "get",
      cancelToken: controller.token,
      timeout: 240000,
    },
  },
  controller,
});

export const loadCurrentPage = (url, isFrozen, controller) => ({
  types: [
    LOAD_TABLE_PAGE.PENDING,
    LOAD_TABLE_PAGE.SUCCESS,
    LOAD_TABLE_PAGE.FAILURE,
  ],
  payload: {
    client: isFrozen ? "frozen" : "nightly",
    request: {
      url,
      method: "get",
      cancelToken: controller.token,
      timeout: 240000,
    },
  },
  controller,
});

export const initiateIncrementChange = (incrementValue) => ({
  type: INITIATE_INCREMENT_CHANGE,
  incrementValue,
});

export const fetchTextExport = (url, isFrozen, fileName) => {
  return (dispatch, getState) => {
    const authToken = getState().auth.nightlyToken;
    const SIMS4SiteByIMMatcher = /Genie-SIMS4SiteByIM/;
    const timeout = fileName.match(SIMS4SiteByIMMatcher) ? 600000 : 120000; // 2min; 10min for SIMS 4 site by IM
    const cancelRequest = () => {
      xhr.abort();
    };
    let textExportTimeout = setTimeout(() => {
      cancelRequest();
      textExportTimeout = null;
    }, timeout);
    let fileURL = BASE_URL[document.location.hostname];
    fileURL += "/" + url;
    // Append dailyFrozen value; XMLHttpRequest bypasses axios logic in store
    fileURL += isFrozen ? "&dailyFrozen=Frozen" : "&dailyFrozen=Daily";
    const xhr = new XMLHttpRequest();
    dispatch(updateTextExportLoading(true));
    xhr.addEventListener("progress", (event) => {
      if (textExportTimeout) {
        clearTimeout(textExportTimeout);
        textExportTimeout = null;
      }
    });
    xhr.open("GET", fileURL);
    xhr.setRequestHeader("Authorization", "Bearer " + authToken);
    xhr.responseType = "blob";
    xhr.onload = function () {
      const blob = new Blob([xhr.response], {
        type: "application/octet-stream",
      });
      saveAs(blob, fileName);
      dispatch(updateTextExportLoading(false));
    };
    xhr.send();
  };
};

export const fetchZipExport = (url, isFrozen) => ({
  types: [
    FETCH_ZIP_EXPORT.PENDING,
    FETCH_ZIP_EXPORT.SUCCESS,
    FETCH_ZIP_EXPORT.FAILURE,
  ],
  payload: {
    client: isFrozen ? "frozen" : "nightly",
    request: {
      url,
      method: "get",
      timeout: 120000, // 2 min timeout
    },
  },
});

export const fetchSevenZipExport = (url, isFrozen) => ({
  types: [
    FETCH_SEVEN_ZIP_EXPORT.PENDING,
    FETCH_SEVEN_ZIP_EXPORT.SUCCESS,
    FETCH_SEVEN_ZIP_EXPORT.FAILURE,
  ],
  payload: {
    client: isFrozen ? "frozen" : "nightly",
    request: {
      url,
      method: "get",
      timeout: 120000, // 2 min timeout
    },
  },
});

export const updateParameterCount = (count) => ({
  type: UPDATE_PARAMETER_COUNT,
  count,
});

export const updateTextExportLoading = (isLoading) => ({
  type: UPDATE_TEXT_EXPORT_LOADING,
  isLoading,
});

export const updateSortedColumn = (column) => ({
  type: UPDATE_SORTED_COLUMN,
  column,
});

export const updateSearchList = (list) => ({
  type: UPDATE_SEARCH_LIST,
  list,
});

export const updateSortingDirection = (direction) => ({
  type: UPDATE_SORTING_DIRECTION,
  direction,
});

export const fetchMyDownloads = (controller) => ({
  types: [
    REFRESH_DOWNLOADS.PENDING,
    REFRESH_DOWNLOADS.SUCCESS,
    REFRESH_DOWNLOADS.FAILURE,
  ],
  payload: {
    client: "nightly",
    request: {
      url: "api/Download/GetMyDownloads?funding_agency=all",
      method: "get",
      cancelToken: controller.token,
    },
  },
  controller,
});

export const refreshDownloads = () => {
  return (dispatch, getState) => {
    const myDownloadsCancelController =
      getState().sessionState.myDownloadsCancelController;
    if (myDownloadsCancelController)
      myDownloadsCancelController.cancel({ msg: "My Downloads cancelled" });
    const newController = axios.CancelToken.source();
    dispatch(fetchMyDownloads(newController));
  };
};

export const clearCurrentTable = () => ({
  type: CLEAR_TABLE,
});

export const clearTable = () => {
  return (dispatch, getState) => {
    const pageCancelController =
      getState().sessionState.newPageCancelController;
    if (pageCancelController)
      pageCancelController.cancel({ msg: "Page load cleared" });
    const tableCancelController = getState().sessionState.tableCancelController;
    if (tableCancelController)
      tableCancelController.cancel({ msg: "Table load cleared" });

    dispatch(clearCurrentTable());
  };
};

export const loadFile = (fileName) => ({
  types: [LOAD_FILE.PENDING, LOAD_FILE.SUCCESS, LOAD_FILE.FAILURE],
  payload: {
    client: "nightly",
    request: {
      url: "api/Download/GetFile?fileName=" + fileName,
      method: "get",
      responseType: "arraybuffer",
      timeout: 120000, // 2 min timeout
    },
  },
  fileName,
});

export const cancelFile = (jobID, hfid, rb) => ({
  types: [CANCEL_FILE.PENDING, CANCEL_FILE.SUCCESS, CANCEL_FILE.FAILURE],
  payload: {
    client: "nightly",
    request: {
      url:
        "api/Download/CancelJob?jobId=" + jobID + "&hfId=" + hfid + "&rb=" + rb,
      method: "get",
    },
  },
});

export const updateTableWidth = (width) => ({
  type: UPDATE_TABLE_WIDTH,
  width,
});

export const initiateSIMSPageLoad = (tablePage) => ({
  type: INITIATE_SIMS_PAGE_LOAD,
  tablePage,
});

export const initiateSIMSTableLoad = () => ({
  type: INITIATE_SIMS_TABLE_LOAD,
});

export const initiatePSNUPageLoad = (tablePage) => ({
  type: INITIATE_PSNU_PAGE_LOAD,
  tablePage,
});

export const initiatePSNUTableLoad = () => ({
  type: INITIATE_PSNU_TABLE_LOAD,
});

export const initiateOUPageLoad = (tablePage) => ({
  type: INITIATE_OU_PAGE_LOAD,
  tablePage,
});

export const initiateOUTableLoad = () => ({
  type: INITIATE_OU_TABLE_LOAD,
});

export const initiateCEEScorePageLoad = (tablePage) => ({
  type: INITIATE_CEE_SCORE_PAGE_LOAD,
  tablePage,
});

export const initiateCEEScoreTableLoad = () => ({
  type: INITIATE_CEE_SCORE_TABLE_LOAD,
});

export const initiateCEEScoreDetailPageLoad = (tablePage) => ({
  type: INITIATE_CEE_SCORE_DETAIL_PAGE_LOAD,
  tablePage,
});

export const initiateCEEScoreDetailTableLoad = () => ({
  type: INITIATE_CEE_SCORE_DETAIL_TABLE_LOAD,
});

export const initiateDRTPageLoad = (tablePage) => ({
  type: INITIATE_DRT_PAGE_LOAD,
  tablePage,
});

export const initiateDRTTableLoad = () => ({
  type: INITIATE_DRT_TABLE_LOAD,
});
