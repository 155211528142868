import React, { memo, useEffect } from "react";
import { FormControlLabel, Switch, FormLabel } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { selectIsFrozen, updateReportState } from "stateMgmt/reportSlice";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

import {
  OU_BY_IM_EXTRACT_DAILY_FROZEN,
  PSNU_BY_IM_EXTRACT_DAILY_FROZEN,
  PSNU_BY_IM_EXTRACT_DREAMS,
  SITE_BY_IM_EXTRACT_DAILY_FROZEN,
  SITE_BY_IM_EXTRACT_DREAMS,
} from "assets/data/reportConstants";
import logger from "services/LoggingService";

const FilterLabel = ({ filterName }) => {
  const globalIsFrozen = useSelector(selectIsFrozen);

  useEffect(() => {
    logger.debug(`rendering frozen toggle: ${globalIsFrozen}`);
    renderCoponent();
  }, [globalIsFrozen]);

  const renderCoponent = () => {
    let filterLabels = [];
    switch (filterName) {
      case SITE_BY_IM_EXTRACT_DAILY_FROZEN:
        //   filterValue = useSelector((state) => state.SiteByIMExtract.isFrozen);
        filterLabels = ["Daily", "Frozen"];
        break;
      case SITE_BY_IM_EXTRACT_DREAMS:
        //   filterValue = useSelector((state) => state.SiteByIMExtract.isDreams);
        filterLabels = ["All SNUs", "Only DREAMS SNUs"];
        break;

      case PSNU_BY_IM_EXTRACT_DAILY_FROZEN:
        //   filterValue = useSelector((state) => state.PSNUByIMExtract.isFrozen);
        filterLabels = ["Daily", "Frozen"];
        break;
      case PSNU_BY_IM_EXTRACT_DREAMS:
        //   filterValue = useSelector((state) => state.PSNUByIMExtract.isDreams);
        filterLabels = ["All SNUs", "Only DREAMS SNUs"];
        break;

      case OU_BY_IM_EXTRACT_DAILY_FROZEN:
        //   filterValue = useSelector((state) => state.OUByIMExtract.isFrozen);
        filterLabels = ["Daily", "Frozen"];
        break;
      default:
        filterLabels = ["Daily", "Frozen"];
    }

    return (
      <FormLabel className="filter-label">
        {globalIsFrozen ? filterLabels[1] : filterLabels[0]}
      </FormLabel>
    );
  };
};

const FilterToggle = ({ filterName }) => {
  const IOSSwitch = styled((props) => {
    const globalIsFrozen = useSelector(selectIsFrozen);
    const dispatch = useDispatch();

    const handleToggle = () => {
      dispatch(updateReportState({ isFrozen: !globalIsFrozen }));
    };

    return (
      <Switch
        focusVisibleClassName=".Mui-focusVisible"
        onChange={handleToggle}
        disableRipple
        {...props}
        checked={globalIsFrozen}
      />
    );
  })(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#cc1f00" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  return (
    <div className="toggle-wrapper">
      <FormControlLabel control={<IOSSwitch sx={{ marginLeft: "15px" }} />} />
      <FilterLabel filterName={filterName} />
    </div>
  );
};

FilterLabel.propTypes = {
  filterName: PropTypes.string.isRequired,
};

FilterToggle.propTypes = {
  filterName: PropTypes.string.isRequired,
};

export default memo(FilterToggle);
