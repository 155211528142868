import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosSession } from "services/AxiosInstance";

import logger from "services/LoggingService";

const initialState = {
  completeOuList: [],
  currentOuList: [],
  currentFilters: [],
  top3Levels: null,
  expanded: [],
  displayReport: false,
  maintainReportState: true,
};

export const fetchData = createAsyncThunk("appState/init", async (options) => {
  // const { apiClient } = extra;
  const axios = axiosSession(options.url);
  const response = await axios.get(options.url);
  return response;
});

export const appSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    init: (state, action) => {
      logger.debug("init called");
      const newState = {
        ...state,
        ...action.payload,
      };
      return newState;
    },
    updateCurrentState: (state, action) => {
      const newState = {
        ...state,
        ...action.payload,
      };
      return newState;
    },
    newReport: (state, action) => {
      const newState = {
        ...initialState,
        ...action.payload,
      };
      return newState;
    },
    setRetportState: (state, action) => {
      const reportInitialState = action.payload.maintainReportState
        ? { ...state }
        : { ...initialState };

      const newState = {
        ...reportInitialState,
        ...action.payload,
      };
      return newState;
    },
    updateExpanded: (state, action) => {
      const newState = {
        ...state,
        expanded: action.payload,
      };
      return newState;
    },
    updateFilter: (state, action) => {
      let found = false;
      let deleteIndex = 999;
      let updateIndex = 0;
      const emptyOptions = [""];
      const newFilters = [...state.currentFilters];
      logger.debug("updateFilter", newFilters);
      logger.debug("payload: ", action.payload);

      //   newFilters.find((el) => el.name === action.payload.name) !== undefined;
      newFilters.forEach((filter, idx) => {
        if (filter.name === action.payload.name) {
          if (
            JSON.stringify(action.payload.options) ===
            JSON.stringify(emptyOptions)
          ) {
            logger.debug(`MARKING FILTER ${idx} FOR DELETION`);
            deleteIndex = idx;
          } else {
            logger.debug("REPLACING FILTER:", action.payload);
            updateIndex = idx;
          }
          found = true;
        }
      });

      if (deleteIndex < 999) {
        logger.debug(`DELETING FILTER ${deleteIndex}`);
        newFilters.splice(deleteIndex, 1);
      } else {
        if (found) {
          newFilters[updateIndex] = action.payload;
        } else {
          logger.debug("ADDING FILTER");
          newFilters.push(action.payload);
        }
      }

      const newState = {
        ...state,
        currentFilters: [...newFilters],
      };
      return newState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        logger.debug("fetchData loading");
        state.status = "loading";
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        logger.debug("fetchData fulfilled");
        state.status = "succeeded";
        state.top3Levels = action.payload.data.globalData;
      })
      .addCase(fetchData.rejected, (state, action) => {
        logger.debug("fetchData error");
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const selectReports = (state) => state.appState;
export const selectOoperatingUnits = (state) => state.appState.ouList;
export const selectCurrentState = (state) => state.appState;
export const selectReportState = (state) => state.appState.maintainReportState;
export const {
  init,
  updateCurrentState,
  updateExpanded,
  updateFilter,
  newReport,
  setRetportState,
} = appSlice.actions;
export default appSlice.reducer;
/* eslint-enable */
