export const SITE_BY_IM_EXTRACT = "Site By IM Extract";
export const PSNU_BY_IM = "PSNU By IM";
export const OU_BY_IM = "OU By IM";
export const CEE_SCORE = "SIMS Country by IM - CEE Score";
export const CEE_SCORE_DETAIL = "SIMS 4 Site by IM - CEE Score and Detail";
export const DATA_REVIEW_TOOL_EXTRACT = "Data Review Tool Extract";
export const MY_DOWNLOADS = "My Downloads";

export const SITE_BY_IM_EXTRACT_RADIO = "SITE_BY_IM_EXTRACT_RADIO";
export const SITE_BY_IM_EXTRACT_DAILY_FROZEN =
  "SITE_BY_IM_EXTRACT_DAILY_FROZEN";
export const SITE_BY_IM_EXTRACT_SNU1 = "SITE_BY_IM_EXTRACT_SNU1";
export const SITE_BY_IM_EXTRACT_PSNU = "SITE_BY_IM_EXTRACT_PSNU";
export const SITE_BY_IM_EXTRACT_DREAMS = "SITE_BY_IM_EXTRACT_DREAMS";
export const SITE_BY_IM_EXTRACT_FUNDING_AGENCY =
  "SITE_BY_IM_EXTRACT_FUNDING_AGENCY";
export const SITE_BY_IM_EXTRACT_MECHANISMS = "SITE_BY_IM_EXTRACT_MECHANISMS";
export const SITE_BY_IM_EXTRACT_INDICATOR = "SITE_BY_IM_EXTRACT_INDICATOR";
export const SITE_BY_IM_EXTRACT_STANDARDIZED_DISAGGREGATE =
  "SITE_BY_IM_EXTRACT_STANDARDIZED_DISAGGREGATE";
export const SITE_BY_IM_EXTRACT_MODALITY = "SITE_BY_IM_EXTRACT_MODALITY";
export const SITE_BY_IM_EXTRACT_FACILITY = "SITE_BY_IM_EXTRACT_FACILITY";
export const SITE_BY_IM_EXTRACT_FISCAL_YEAR = "SITE_BY_IM_EXTRACT_FISCAL_YEAR";

// PSNU By IM
export const PSNU_BY_IM_EXTRACT_RADIO = "PSNU_BY_IM_EXTRACT_RADIO";
export const PSNU_BY_IM_EXTRACT_DAILY_FROZEN =
  "PSNU_BY_IM_EXTRACT_DAILY_FROZEN";
export const PSNU_BY_IM_EXTRACT_SNU1 = "PSNU_BY_IM_EXTRACT_SNU1";
export const PSNU_BY_IM_EXTRACT_PSNU = "PSNU_BY_IM_EXTRACT_PSNU";
export const PSNU_BY_IM_EXTRACT_DREAMS = "PSNU_BY_IM_EXTRACT_DREAMS";
export const PSNU_BY_IM_EXTRACT_FUNDING_AGENCY =
  "PSNU_BY_IM_EXTRACT_FUNDING_AGENCY";
export const PSNU_BY_IM_EXTRACT_MECHANISMS = "PSNU_BY_IM_EXTRACT_MECHANISMS";
export const PSNU_BY_IM_EXTRACT_INDICATOR = "PSNU_BY_IM_EXTRACT_INDICATOR";
export const PSNU_BY_IM_EXTRACT_STANDARDIZED_DISAGGREGATE =
  "PSNU_BY_IM_EXTRACT_STANDARDIZED_DISAGGREGATE";
export const PSNU_BY_IM_EXTRACT_MODALITY = "PSNU_BY_IM_EXTRACT_MODALITY";
export const PSNU_BY_IM_EXTRACT_FISCAL_YEAR = "PSNU_BY_IM_EXTRACT_FISCAL_YEAR";

// OU By IM
export const OU_BY_IM_EXTRACT_RADIO = "OU_BY_IM_EXTRACT_RADIO";
export const OU_BY_IM_EXTRACT_DAILY_FROZEN = "OU_BY_IM_EXTRACT_DAILY_FROZEN";
export const OU_BY_IM_EXTRACT_FUNDING_AGENCY =
  "OU_BY_IM_EXTRACT_FUNDING_AGENCY";
export const OU_BY_IM_EXTRACT_MECHANISMS = "OU_BY_IM_EXTRACT_MECHANISMS";
export const OU_BY_IM_EXTRACT_INDICATOR = "OU_BY_IM_EXTRACT_INDICATOR";
export const OU_BY_IM_EXTRACT_STANDARDIZED_DISAGGREGATE =
  "OU_BY_IM_EXTRACT_STANDARDIZED_DISAGGREGATE";
export const OU_BY_IM_EXTRACT_MODALITY = "OU_BY_IM_EXTRACT_MODALITY";
export const OU_BY_IM_EXTRACT_FISCAL_YEAR = "OU_BY_IM_EXTRACT_FISCAL_YEAR";

// SIMS CEE SCORE
export const CEE_SCORE_COUNTRY_NAME = "CEE_SCORE_COUNTRY_NAME";
export const CEE_SCORE_FUNDING_AGENCY = "CEE_SCORE_FUNDING_AGENCY";
export const CEE_SCORE_PERIOD = "CEE_SCORE_PERIOD";
export const CEE_SCORE_ASSESSMENT_TYPE = "CEE_SCORE_ASSESSMENT_TYPE";
export const CEE_SCORE_CORE_ESSENTIAL_ELEMENTS =
  "CEE_SCORE_CORE_ESSENTIAL_ELEMENTS";

// SIMS CEE SCORE AND DETAIL
export const CEE_SCORE_DETAIL_COUNTRY_NAME = "CEE_SCORE_DETAIL_COUNTRY_NAME";
export const CEE_SCORE_DETAIL_FUNDING_AGENCY =
  "CEE_SCORE_DETAIL_FUNDING_AGENCY";
export const CEE_SCORE_DETAIL_PERIOD = "CEE_SCORE_DETAIL_PERIOD";
export const CEE_SCORE_DETAIL_ASSESSMENT_TYPE =
  "CEE_SCORE_DETAIL_ASSESSMENT_TYPE";
export const CEE_SCORE_DETAIL_CORE_ESSENTIAL_ELEMENTS =
  "CEE_SCORE_DETAIL_CORE_ESSENTIAL_ELEMENTS";

// DRT
export const DRT_SNU1 = "DRT_SNU1";
export const DRT_PSNU = "DRT_PSNU";
export const DRT_CHECK_LEVEL = "DRT_CHECK_LEVEL";
export const DRT_FUNDING_AGENCY = "DRT_FUNDING_AGENCY";
export const DRT_MECHANISMS = "DRT_MECHANISMS";
export const DRT_PROGRAM_AREA = "DRT_PROGRAM_AREA";
export const DRT_FLAG = "DRT_FLAG";

export const reportList = {
  reports: [
    {
      name: SITE_BY_IM_EXTRACT,
      display: "Site By IM Extract",
      showTree: true,
      showFrozen: true,
      filterUrl: "/SiteByIMs/GetSiteByIMFilterValues",
      filters: [
        {
          name: SITE_BY_IM_EXTRACT_RADIO,
          type: "radio",
          header: "SITE_BY_IM_EXTRACT_RADIO",
          toolTipText: "",
          field: "indicator",
          elements: [],
        },
        {
          name: SITE_BY_IM_EXTRACT_SNU1,
          type: "checkbox",
          header: "",
          toolTipText: "",
          field: "SNU1",
          elements: [],
        },
        {
          name: SITE_BY_IM_EXTRACT_PSNU,
          type: "checkbox",
          header: "",
          toolTipText: "",
          field: "PSNU",
          elements: [],
        },
        {
          name: SITE_BY_IM_EXTRACT_FUNDING_AGENCY,
          type: "checkbox",
          header: "",
          toolTipText: "",
          field: "funding_agency",
          elements: [],
        },
        {
          name: SITE_BY_IM_EXTRACT_MECHANISMS,
          type: "checkbox",
          header: "",
          toolTipText: "",
          field: "mechanisms",
          elements: [],
        },
        {
          name: SITE_BY_IM_EXTRACT_INDICATOR,
          type: "checkbox",
          header: "",
          toolTipText: "",
          field: "indicator",
          elements: [],
        },
        {
          name: SITE_BY_IM_EXTRACT_STANDARDIZED_DISAGGREGATE,
          type: "checkbox",
          header: "",
          toolTipText: "",
          field: "disaggregate",
          elements: [],
        },
        {
          name: SITE_BY_IM_EXTRACT_MODALITY,
          type: "checkbox",
          header: "",
          toolTipText: "",
          field: "modality",
          elements: [],
        },
        {
          name: SITE_BY_IM_EXTRACT_FACILITY,
          type: "checkbox",
          header: "",
          toolTipText: "",
          field: "facility",
          elements: [],
        },
      ],
    },
    // {
    //   name: PSNU_BY_IM,
    //   display: "PSNU By IM",
    //   showTree: true,
    //   showFrozen: true,
    //   filterUrl: "/",
    //   filters: [
    //     {
    //       name: PSNU_BY_IM_EXTRACT_RADIO,
    //       type: "radio",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: PSNU_BY_IM_EXTRACT_DAILY_FROZEN,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: PSNU_BY_IM_EXTRACT_SNU1,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: PSNU_BY_IM_EXTRACT_PSNU,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: PSNU_BY_IM_EXTRACT_DREAMS,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: PSNU_BY_IM_EXTRACT_FUNDING_AGENCY,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: PSNU_BY_IM_EXTRACT_MECHANISMS,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: PSNU_BY_IM_EXTRACT_INDICATOR,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: PSNU_BY_IM_EXTRACT_STANDARDIZED_DISAGGREGATE,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: PSNU_BY_IM_EXTRACT_MODALITY,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: PSNU_BY_IM_EXTRACT_FISCAL_YEAR,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //   ],
    // },
    // {
    //   name: OU_BY_IM,
    //   display: "OU By IM",
    //   showTree: true,
    //   showFrozen: true,
    //   filterUrl: "/",
    //   filters: [
    //     {
    //       name: OU_BY_IM_EXTRACT_RADIO,
    //       type: "radio",
    //       header: "Calculations Across Time, Safe for Compare",
    //       toolTipText:
    //         "Filter defaults to include all data. To analyze partner shifts across quarters, you may choose only Safe for Net New is Yes to analyze no shifts or 1:1 partner shifts from 1 quarter ago, or choose Safe for Viral Load Coverage (VLC) for shifts from 2 quarters ago. If your analysis includes shifts for 1 and 2 quarters ago, then choose the Both option.",
    //       elements: [],
    //     },
    //     {
    //       name: OU_BY_IM_EXTRACT_FUNDING_AGENCY,
    //       type: "checkbox",
    //       header: "Funding Agency",
    //       toolTipText:
    //         "The USG agency or Operating Division selected will receive the funding from OGAC.",
    //       elements: [],
    //     },
    //     {
    //       name: OU_BY_IM_EXTRACT_MECHANISMS,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: OU_BY_IM_EXTRACT_INDICATOR,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: OU_BY_IM_EXTRACT_STANDARDIZED_DISAGGREGATE,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: OU_BY_IM_EXTRACT_MODALITY,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: OU_BY_IM_EXTRACT_FISCAL_YEAR,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //   ],
    // },
    // {
    //   name: CEE_SCORE,
    //   display: "SIMS Country by IM - CEE Score",
    //   showTree: true,
    //   showFrozen: false,
    //   filterUrl: "/",
    //   filters: [
    //     {
    //       name: CEE_SCORE_COUNTRY_NAME,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: CEE_SCORE_FUNDING_AGENCY,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: CEE_SCORE_PERIOD,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: CEE_SCORE_ASSESSMENT_TYPE,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: CEE_SCORE_CORE_ESSENTIAL_ELEMENTS,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //   ],
    // },
    // {
    //   name: CEE_SCORE_DETAIL,
    //   display: "SIMS 4 Site by IM - CEE Score and Detail",
    //   showTree: true,
    //   showFrozen: false,
    //   filterUrl: "/",
    //   filters: [
    //     {
    //       name: CEE_SCORE_DETAIL_COUNTRY_NAME,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: CEE_SCORE_DETAIL_FUNDING_AGENCY,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: CEE_SCORE_DETAIL_PERIOD,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: CEE_SCORE_DETAIL_ASSESSMENT_TYPE,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: CEE_SCORE_DETAIL_CORE_ESSENTIAL_ELEMENTS,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //   ],
    // },
    // {
    //   name: DATA_REVIEW_TOOL_EXTRACT,
    //   display: "Data Review Tool Extract",
    //   showTree: true,
    //   showFrozen: false,
    //   filterUrl: "/",
    //   filters: [
    //     {
    //       name: DRT_SNU1,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: DRT_PSNU,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: DRT_CHECK_LEVEL,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: DRT_FUNDING_AGENCY,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: DRT_MECHANISMS,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: DRT_PROGRAM_AREA,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    //     {
    //       name: DRT_FLAG,
    //       type: "checkbox",
    //       header: "",
    //       toolTipText: "",
    //       elements: [],
    //     },
    // ],
    // },
  ],
};
