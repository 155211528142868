import React, { useState, useEffect } from "react";
import {
  Checkbox,
  MenuItem,
  OutlinedInput,
  Radio,
  Select,
  ListItemText,
  Switch,
} from "@mui/material";
import { clipString } from "utils/utils";
import { v4 as uuidv4 } from "uuid";
import { updateFilter, selectReportState } from "stateMgmt/appSlice";
import { useDispatch, useSelector } from "react-redux";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";

import logger from "services/LoggingService";

import PropTypes from "prop-types";

const FilterComponent = (props) => {
  const dispatch = useDispatch();
  const maintainState = useSelector(selectReportState);
  const selectorType = props.data.type;
  const selectorName = props.data.name;
  const selectorToolTip = props.data.toolTipText;
  const selectorElements = [...props.data.elements];
  const [selectionArray, setSelectionArray] = useState([]);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    logger.debug(
      `Pre-Processing FilterComponent. Maintiain State: ${maintainState}`
    );
    if (!maintainState) {
      setSelectionArray([]);
      setChecked(false);
    }
  }, [maintainState]);

  const createRadioSelect = (selectionArray, name, toolTipText, elements) => {
    const key = uuidv4();
    return (
      <div className="filter">
        <div className="label-div">{name}</div>
        <Select
          fullWidth
          sx={{
            width: "100%",
            height: "4vh",
            fontSize: "0.7rem",
            backgroundColor: "#ffffff",
          }}
          labelId={`radio:${key}`}
          id={`radio:${key}`}
          value={selectionArray}
          onChange={(e) => {
            handleComponentChange(e, name);
          }}
          label={"Outlined"}
          variant="standard"
          input={<OutlinedInput />}
          renderValue={(selected) => selected.join(", ")}
        >
          {elements.map((element) => (
            <MenuItem key={element.id} value={element.value}>
              <Radio checked={selectionArray.indexOf(element.value) > -1} />
              <ListItemText primary={element.value} />
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  };

  const createCheckSelect = (selectionArray, name, toolTipText, elements) => {
    logger.debug(`createCheckSelect; name: ${name}`);
    const key = uuidv4();
    logger.debug("checkbox:", name);

    return (
      <div className="filter">
        <div className="switch-row ">
          <div className="sel-all-sw">
            <div>Select All</div>
            <Switch
              size="small"
              checked={checked}
              onChange={(e) => selectAll(e, name)}
            />
          </div>
          <div>
            <Tooltip
              title={toolTipText}
              placement="right"
              sx={{ height: "18px", width: "18px" }}
              arrow
              className="tool-tip"
            >
              <HelpIcon />
            </Tooltip>
          </div>
        </div>
        <div className="label-div">{name}</div>
        {/* <div> */}
        <Select
          fullWidth
          id={`checkbox:${key}`}
          sx={{ height: "4vh" }}
          multiple
          value={selectionArray}
          onChange={(e) => {
            handleComponentChange(e, name);
          }}
          input={<OutlinedInput />}
          renderValue={(selected) => clipString(selected.join(", "), 30)}
        >
          {elements.map((element) => (
            <MenuItem key={element.id} value={element.value}>
              <Checkbox checked={selectionArray.indexOf(element.value) > -1} />
              <ListItemText primary={element.value} />
            </MenuItem>
          ))}
        </Select>
        {/* </div> */}
      </div>
    );
  };

  const selectAll = (event, name) => {
    const tmpArray = [];
    if (event.target.checked) {
      selectorElements.forEach((el) => tmpArray.push(el.value));
    }

    event.target.value = [...tmpArray];
    handleComponentChange(event, name);
    setChecked(event.target.checked);
  };

  const handleComponentChange = (e, targetName) => {
    const value =
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value;

    setSelectionArray(value);
    dispatch(updateFilter({ name: targetName, options: value }));
  };

  const generateComponent = () => {
    let component = <></>;
    switch (selectorType) {
      case "radio":
        component = createRadioSelect(
          selectionArray,
          selectorName,
          selectorToolTip,
          selectorElements,
          handleComponentChange
        );

        break;

      case "checkbox":
        component = createCheckSelect(
          selectionArray,
          selectorName,
          selectorToolTip,
          selectorElements,
          handleComponentChange
        );

        break;

      default:
        logger.error("Invalid component requested");
    }

    return component;
  };

  return <>{generateComponent()}</>;
};

FilterComponent.propTypes = {
  data: PropTypes.any.isRequired,
};

export default React.memo(FilterComponent);
