import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import StageReport from "components/stageReport";
import FilterView from "components/filterView";
import DisplayReport from "components/displayReport";
import Header from "components/Header";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentState, fetchData } from "stateMgmt/appSlice";
import { fetchFilters } from "stateMgmt/reportSlice";
import { BASE_URL } from "constants/Session";
import logger from "services/LoggingService";

import "./App.scss";

const router = createBrowserRouter([
  {
    path: "/",
    element: <StageReport />,
  },
  {
    path: "/report",
    element: <DisplayReport />,
  },
]);

function App() {
  const currentState = useSelector(selectCurrentState);
  const dispatch = useDispatch();
  const url =
    "/api/organisation_unit/organisation_unitTop3LevelsTree?dailyFrozen=Frozen";

  const url2 =
    "/api/SiteByIMs/GetSiteByIMFilterValues?field=indicator&dailyFrozen=Daily";

  useEffect(() => {
    logger.debug("Pre-Processing App", currentState);
    dispatch(
      fetchData({
        url: `${BASE_URL[document.location.hostname]}${url}`,
        action: "init",
      })
    );

    // FIXME: Move to filter component
    dispatch(
      fetchFilters({
        url: `${BASE_URL[document.location.hostname]}${url2}`,
        action: "getFilters",
      })
    );
  }, []);

  return (
    <>
      <div id="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={2}>
            <div>
              <FilterView />
            </div>
          </Grid>
          <Grid item xs={10}>
            <div className="App-body">
              <RouterProvider router={router} />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default App;
