/* jshint esversion: 6 */
import { asyncActionType } from "./ActionType";

export const OKTA_URL = {
  // Dev using local proxy
  "127.0.0.1": "http://localhost:9001/",
  localhost: "http://localhost:9001/",

  // Test
  "genie.testing.datim.org": "https://genie.testing.datim.org/",

  // Prod
};

export const BASE_URL = {
  // Local
  // '127.0.0.1': 'http://localhost:51169/',
  // 'localhost': 'http://localhost:51169/',
  "127.0.0.1": "https://wave.test.pdap.pepfar.net",
  localhost: "https://wave.test.pdap.pepfar.net",

  // Test
  "genie.testing.datim.org": "https://wave.test.pdap.pepfar.net",
  "double.testing.datim.org": "https://wave.test.pdap.pepfar.net",
  "nr.testing.datim.org": "https://wave.test.pdap.pepfar.net",
  "dev.datim.org": "https://wave.test.pdap.pepfar.net",
  "test.datim.org": "https://wave.test.pdap.pepfar.net",
  "triage.testing.datim.org": "https://wave.test.pdap.pepfar.net",

  // Prod
  "www.datim.org": "https://wave.pdap.pepfar.net",
  "datim.org": "https://wave.pdap.pepfar.net",
  "final.datim.org": "https://wave.pdap.pepfar.net",
};

export const AUTH_URL = {
  "127.0.0.1": "https://double.testing.datim.org",
  localhost: "https://double.testing.datim.org",

  "genie.testing.datim.org": "https://double.testing.datim.org",
  "double.testing.datim.org": "/",
  "nr.testing.datim.org": "https://genie.testing.datim.org",
  "dev.datim.org": "https://genie.testing.datim.org",
  "test.datim.org": "https://genie.testing.datim.org",
  "triage.testing.datim.org": "https://genie.testing.datim.org",

  // Prod
  "www.datim.org": "https://www.datim.org/pdapsession",
  "datim.org": "https://www.datim.org/pdapsession",
  "final.datim.org": "https://www.datim.org/pdapsession",
};

export const EASE_OUT_SINE = (x) => Math.sin((x * Math.PI) / 2);
export const PARAMETER_FILTER_HEIGHT = 16;

export const SET_REPORT_NAME = "SET_REPORT_NAME";
export const LOAD_TOP_3_LEVELS = asyncActionType("LOAD_TOP_3_LEVELS");
export const LOAD_ICPI = asyncActionType("LOAD_ICPI");
export const LOAD_FROZEN_ICPI = asyncActionType("LOAD_FROZEN_ICPI");
export const LOAD_SIM_INDICATOR = asyncActionType("LOAD_SIM_INDICATOR");
export const LOAD_SIM_FISCAL_YEAR = asyncActionType("LOAD_SIM_FISCAL_YEAR");
export const LOAD_LAST_SYNC = asyncActionType("LOAD_LAST_SYNC");
export const LOAD_LAST_SYNC_FROZEN = asyncActionType("LOAD_LAST_SYNC_FROZEN");
export const UPDATE_REPORT_TYPE = "UPDATE_REPORT_TYPE";
// export const UPDATE_ACTIVE_COUNTRIES = "UPDATE_ACTIVE_COUNTRIES";
export const FETCH_REPORT = asyncActionType("FETCH_REPORT");
export const LOAD_CURRENT_TABLE = asyncActionType("LOAD_CURRENT_TABLE");
export const LOAD_TABLE_PAGE = asyncActionType("LOAD_TABLE_PAGE");
export const INITIATE_INCREMENT_CHANGE = "INITIATE_INCREMENT_CHANGE";
export const FETCH_TEXT_EXPORT = asyncActionType("FETCH_TEXT_EXPORT");
export const FETCH_ZIP_EXPORT = asyncActionType("FETCH_ZIP_EXPORT");
export const CLEAR_SELECTED_FILTERS = asyncActionType("CLEAR_SELECTED_FILTERS");
export const FETCH_SEVEN_ZIP_EXPORT = asyncActionType("FETCH_SEVEN_ZIP_EXPORT");
export const INITIATE_SEVEN_ZIP_EXPORT = "INITIATE_SEVEN_ZIP_EXPORT";
export const INITIATE_ZIP_EXPORT = "INITIATE_ZIP_EXPORT";
export const INITIATE_TEXT_EXPORT = "INITIATE_TEXT_EXPORT";
export const UPDATE_PARAMETER_COUNT = "UPDATE_PARAMETER_COUNT";
export const UPDATE_RESULT_TARGET = asyncActionType("UPDATE_RESULT_TARGET");
export const UPDATE_SORTED_COLUMN = asyncActionType("UPDATE_SORTED_COLUMN");
export const UPDATE_SEARCH_LIST = asyncActionType("UPDATE_SEARCH_LIST");
export const UPDATE_SORTING_DIRECTION = asyncActionType(
  "UPDATE_SORTING_DIRECTION"
);
export const REFRESH_DOWNLOADS = asyncActionType("REFRESH_DOWNLOADS");
export const CLEAR_TABLE = "CLEAR_TABLE";
export const LOAD_FILE = asyncActionType("LOAD_FILE");
export const CANCEL_FILE = asyncActionType("CANCEL_FILE");
export const UPDATE_TABLE_WIDTH = "UPDATE_TABLE_WIDTH";
export const INITIATE_SIMS_PAGE_LOAD = "INITIATE_SIMS_PAGE_LOAD";
export const INITIATE_SIMS_TABLE_LOAD = "INITIATE_SIMS_TABLE_LOAD";
export const INITIATE_PSNU_PAGE_LOAD = "INITIATE_PSNU_PAGE_LOAD";
export const INITIATE_PSNU_TABLE_LOAD = "INITIATE_PSNU_TABLE_LOAD";
export const INITIATE_OU_PAGE_LOAD = "INITIATE_OU_PAGE_LOAD";
export const INITIATE_OU_TABLE_LOAD = "INITIATE_OU_TABLE_LOAD";
export const INITIATE_CEE_SCORE_DETAIL_PAGE_LOAD =
  "INITIATE_CEE_SCORE_DETAIL_PAGE_LOAD";
export const INITIATE_CEE_SCORE_DETAIL_TABLE_LOAD =
  "INITIATE_CEE_SCORE_DETAIL_TABLE_LOAD";
export const INITIATE_CEE_SCORE_PAGE_LOAD = "INITIATE_CEE_SCORE_PAGE_LOAD";
export const INITIATE_CEE_SCORE_TABLE_LOAD = "INITIATE_CEE_SCORE_TABLE_LOAD";
export const INITIATE_DRT_PAGE_LOAD = "INITIATE_DRT_PAGE_LOAD";
export const INITIATE_DRT_TABLE_LOAD = "INITIATE_DRT_TABLE_LOAD";
// export const UPDATE_EXPANDED = 'UPDATE_EXPANDED';
export const UPDATE_TEXT_EXPORT_LOADING = "UPDATE_TEXT_EXPORT_LOADING";
