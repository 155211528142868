import React, { useEffect } from "react";
import ReportTable from "./Reports/Reports/ReportTable/ReportTable";
import {
  // List,
  // ListItem,
  Button,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentState, setRetportState } from "stateMgmt/appSlice";
import { selectReportName } from "stateMgmt/reportSlice";
import logger from "services/LoggingService";
import { useNavigate } from "react-router-dom";

import "App.scss";

const StageReport = (props) => {
  const currentState = useSelector(selectCurrentState);
  const currentReportName = useSelector(selectReportName);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const runReport = () => {
    dispatch(
      setRetportState({ displayReport: true, maintainReportState: true })
    );
    navigate("/report");
  };

  useEffect(() => {
    logger.debug("Pre-Processing StageReport", currentState);
  }, [currentState, currentReportName]);

  return (
    <>
      <div id="STAGING" className="staging-container">
        <div className="stage-header">
          <div className="btn-row">
            <h1>{currentReportName}</h1>
            <div>
              <Button
                variant="contained"
                sx={{ height: "5vh", margin: "auto", fontSize: "0.7rem" }}
                onClick={() => {
                  runReport();
                }}
              >
                Run Report
              </Button>
            </div>
          </div>
          <h2>DATIM data as of: </h2>
          <h3>Genie report updated: </h3>
          <h4>Current period(s):</h4>
          <div className="link-div">
            <a
              href="https://datim.zendesk.com/hc/en-us/articles/360033053192"
              target="_blank"
              rel="noopener noreferrer"
            >
              Users Guide and Data Dictionary
            </a>
          </div>

          <div>
            <ReportTable />
          </div>
        </div>
        <hr />
        <div className="filter-container">
          {currentState.currentFilters?.map((filter) => (
            <div className="filter-display" key="{filter.name}">
              {filter.name}
              {/* <div>
                {filter.options.map((value) => ({ value }))}
              </div> */}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

// StageReport.propTypes = {
//   initialState: PropTypes.object.isRequired,
// };

export default React.memo(StageReport);
