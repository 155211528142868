export const generateOUString = (selectedCountryNames, top3Levels) => {
  let allCountryNames = ''
  const countryNameKey = {}
  const addRegionToList = (regionalIndexNum) => {
    top3Levels.level2[regionalIndexNum].children.forEach((country, indexNum) => {
      if(!countryNameKey[country.label]) countryNameKey[country.label] = true
    })
  }
  selectedCountryNames.forEach((countryName, index) => {
    // Turn global or regional into list of countries, avoiding duplicates
    if(countryNameKey[countryName]){
      // Already exists; do nothing
    }else if(countryName === 'Global'){
      // Iterate through all regions
      for(let x=0; x<4; x++){
        addRegionToList(x);
      }
    }else if(countryName === 'Africa'){
      addRegionToList(0);
    }else if(countryName === 'Americas'){
      addRegionToList(1);
    }else if(countryName === 'Asia'){
      addRegionToList(2);
    }else if(countryName === 'Europe'){
      addRegionToList(3);
    }else{
      // Not global or region
      countryNameKey[countryName] = true
    }
  })
  // Turn into string
  allCountryNames = Object.keys(countryNameKey).sort((a, b) => a.localeCompare(b)).join(', ')
  return allCountryNames;


}
