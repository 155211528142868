import Raven from "raven-js";
import logger from "services/LoggingService";

export const asyncFunctionMiddleware = (storeAPI) => (next) => (action) => {
  // If the "action" is actually a function instead...
  if (typeof action === "function") {
    // then call the function and pass `dispatch` and `getState` as arguments
    return action(storeAPI.dispatch, storeAPI.getState);
  }

  // Otherwise, it's a normal action - send it onwards
  return next(action);
};

export const reduxLogger = (store) => (next) => (action) => {
  const result = next(action);
  logger.debug(`REDUX dispatching: ${action.type}`, store.getState());
  return result;
};

export const crashReporter = (store) => (next) => (action) => {
  try {
    return next(action);
  } catch (err) {
    logger.error("Caught an exception!", err);
    Raven.captureException(err, {
      extra: {
        action,
        state: store.getState(),
      },
    });
    throw err;
  }
};
