export const determineOUShorthand = (countryList, top3Levels) => {
  if (countryList.length === 1) return countryList[0];
  const regionalInfo = {};
  if (top3Levels)
    top3Levels.level2.forEach(
      (region) => (regionalInfo[region.label] = region.children),
    );
  let finalOUName = "";
  const listToCheck = [];

  for (let x = 0; x < countryList.length; x++) {
    if (countryList[x] === "Global") {
      finalOUName = "Global";
      x = countryList.length;
    } else if (
      countryList[x] === "Africa" ||
      countryList[x] === "Americas" ||
      countryList[x] === "Asia" ||
      countryList[x] === "Europe"
    ) {
      if (!finalOUName) {
        finalOUName = countryList[x];
      } else {
        finalOUName = "MultipleOUs";
        x = countryList.length;
      }
    } else {
      // Add to list, check after loop ends
      listToCheck.push(countryList[x]);
    }
  }

  if (!finalOUName) finalOUName = "MultipleOUs";
  else if (finalOUName !== "MultipleOUs" && top3Levels) {
    for (let x = 0; x < listToCheck.length; x++) {
      let isCountryFound = false;
      regionalInfo[finalOUName].forEach((country) => {
        if (country.label === listToCheck[x]) isCountryFound = true;
      });
      if (!isCountryFound) {
        finalOUName = "MultipleOUs";
        x = listToCheck.length;
      }
    }
  }

  return finalOUName;
};
