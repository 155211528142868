import React, { useEffect, useState } from "react";
import FilterComponent from "./filterComponent";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { selectReportState } from "stateMgmt/reportSlice";
import { FormControlLabel } from "@mui/material";
import FilterToggle from "./FilterToggle";
import logger from "services/LoggingService";

const FilterList = () => {
  const currentReportState = useSelector(selectReportState);
  const [filterList, setFilterList] = useState([]);

  logger.debug("Reports List: ", currentReportState.reports);

  useEffect(() => {
    logger.debug("FilterList useEffect called");
    const report = currentReportState.reports.find(
      (report) => report.name === currentReportState.currentReportName
    );

    const filterList = report.filters;
    const componentList = getListComponents(filterList);
    setFilterList([...componentList]);
  }, [currentReportState.currentReportName]);

  const getListComponents = (targetArray) => {
    const retVal = targetArray.map((data) => (
      <div key={uuidv4()}>
        <FilterComponent data={data} />
      </div>
    ));

    return retVal;
  };

  return (
    <>
      <div className={filterList.length === 0 ? "filter-list" : "filter-list"}>
        <br />
        <div>
          <FormControlLabel
            control={<FilterToggle />}
            label="Daily/Frozen"
            labelPlacement="top"
          />
        </div>
        {filterList}
      </div>
      <div className={filterList.length > 0 ? "hidden" : "no-filters"}>
        None Available for this Report
      </div>
    </>
  );
};

export default React.memo(FilterList);
