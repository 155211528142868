/* lint-disable react/prop-type */

import React, { useState, useEffect } from "react";
import FilterList from "./filterList";
import { FormControl, Select, MenuItem } from "@mui/material";
import Tree from "./Reports/OperatingUnit/Tree/Tree";
import { useDispatch, useSelector } from "react-redux";
import {
  // updateCurrentState,
  newReport,
  selectCurrentState,
} from "stateMgmt/appSlice";
import { selectReportState } from "stateMgmt/reportSlice";
import { v4 as uuidv4 } from "uuid";
import logger from "services/LoggingService";

const FilterView = (props) => {
  const dispatch = useDispatch();
  const currentState = useSelector(selectCurrentState);
  const currentReportState = useSelector(selectReportState);
  logger.debug("FilterView called with currentReportState", currentReportState);

  const [currentReport, setCurrentReport] = useState(currentReportState.name);
  // const [frozen, setFrozen] = useState(false);

  const chooseReport = (e) => {
    setCurrentReport(e.target.value);
    dispatch(newReport({ currentReport: e.target.value }));
  };

  useEffect(() => {
    if (!currentState.maintainReportState) {
      // setFrozen(false);
      setCurrentReport(currentReportState.currentReportName);
    }
  }, [currentState.maintainReportState]);

  return (
    <>
      <div id="FILTERS">
        <FormControl sx={{ mb: 3 }} fullWidth>
          <div className="report-select">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currentReport}
              sx={{
                width: "100%",
                height: "35px",
                fontSize: "0.7rem",
                backgroundColor: "#ffffff",
              }}
              onChange={(e) => chooseReport(e)}
            >
              {currentReportState.reports.map((report) => {
                return (
                  <MenuItem
                    key={uuidv4()}
                    value={report.name}
                    aria-label="r1 button"
                  >
                    {report.display}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <Tree />
          <FilterList />
        </FormControl>
      </div>
    </>
  );
};

export default React.memo(FilterView);
/* lint-enable react/prop-type */
