import { createSlice } from "@reduxjs/toolkit";

import logger from "services/LoggingService";

const initialState = {
  currentCountriesSelected: [],
  countriesLoaded: false,
  currentCountryUIDString: null,
  selectedCountryNames: null,
  ICPI: null,
  frozenICPI: false,
  lastLoad: null,
  lastLoadFrozen: null,
  isCurrentTableLoading: false,
  initiateCEEScoreDetailTableLoad: null,
  initiateCEEScoreDetailPageLoad: null,
  newPageCancelController: null,
  tableCancelController: null,
  localFundingAgency: null,
  expanded: [],
};

export const sessionSlice = createSlice({
  name: "sessionState",
  initialState,
  reducers: {
    updateActiveCountries: (state, action) => {
      // debugger;
      logger.debug("updateActiveCountries called");
      return {
        ...state,
        currentCountriesSelected: action.payload.countryList,
        currentCountryUIDString: action.payload.uidString,
        selectedCountryNames: action.payload.selectedCountryNames,
      };
    },
    updateExpanded: (state, action) => {
      logger.debug("updateExpanded called");
      return {
        ...state,
        expanded: action.payload.expanded,
      };
    },
  },
});

export const selectSessionState = (state) => state.sessionState;
export const { updateActiveCountries, updateExpanded } = sessionSlice.actions;

export default sessionSlice.reducer;
